import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 576.000000 720.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,720.000000) scale(0.100000,-0.100000)">
<path d="M1338 5093 c-10 -2 -18 -13 -18 -24 0 -10 -7 -19 -15 -19 -8 0 -15
-6 -15 -13 0 -8 -9 -22 -21 -33 -12 -10 -30 -31 -41 -47 l-19 -27 187 -658
c207 -728 311 -1093 349 -1223 30 -103 31 -128 5 -255 -10 -54 -33 -175 -50
-269 -17 -93 -40 -215 -50 -270 -39 -209 -69 -371 -72 -395 -3 -21 2 -25 32
-31 51 -9 212 -44 389 -83 l154 -34 38 42 c21 22 39 49 39 59 0 20 3 20 49 7
32 -9 36 -7 68 28 l34 37 -30 9 c-40 12 -40 24 0 18 28 -4 48 8 49 28 0 12
-48 33 -84 36 -22 2 -44 8 -50 14 -18 18 -26 11 -26 -26 0 -21 4 -33 10 -29 6
4 8 11 5 16 -8 11 45 12 63 0 11 -7 11 -9 -2 -14 -9 -4 -16 -2 -16 3 0 6 -5
10 -11 10 -7 0 -4 -11 6 -26 9 -15 11 -23 4 -19 -13 9 -53 16 -81 16 -10 0
-20 4 -23 10 -4 5 -18 10 -33 10 -17 1 -21 3 -9 6 9 2 15 9 12 14 -3 5 0 6 8
3 15 -5 41 41 32 57 -4 5 1 9 9 9 8 0 18 -5 21 -10 5 -8 11 -7 20 0 8 7 20 8
26 4 7 -4 9 -3 6 3 -4 6 0 14 9 17 11 4 18 -1 21 -14 3 -11 9 -20 13 -20 15 0
17 25 4 49 -11 21 -13 22 -17 5 -3 -13 -5 -11 -6 9 0 15 4 27 11 27 9 0 9 3 1
11 -8 8 -17 5 -32 -10 -12 -12 -27 -19 -34 -15 -9 5 -9 4 0 -6 10 -11 15 -11
28 0 12 10 15 10 15 -1 0 -24 -35 -40 -55 -25 -16 12 -15 14 9 41 14 15 26 35
26 43 0 9 7 13 19 9 17 -4 19 0 16 34 -3 22 0 39 5 39 20 0 14 36 -8 47 -26
14 -43 9 -37 -10 2 -7 6 -29 8 -49 4 -28 1 -38 -9 -38 -8 0 -11 -6 -8 -15 9
-22 -5 -38 -22 -25 -7 7 -19 10 -26 8 -20 -7 -38 17 -38 51 0 35 20 53 21 19
l0 -23 9 23 c5 12 14 22 20 22 5 0 10 4 10 10 0 5 -7 7 -15 4 -11 -5 -13 -2
-8 12 5 14 3 16 -11 11 -10 -4 -21 -7 -26 -7 -7 0 -11 -16 -24 -86 -5 -25 3
-38 25 -46 9 -4 19 -16 22 -27 7 -28 -22 -28 -51 -1 -12 11 -27 19 -34 18 -7
-2 -14 8 -16 21 -3 20 -8 22 -34 17 -17 -3 -34 -6 -39 -6 -5 0 -9 -7 -9 -15 0
-21 -27 -19 -34 3 -8 24 -66 18 -73 -8 -3 -10 -15 -21 -26 -24 -45 -12 -47 -9
-32 41 8 25 13 51 12 57 -1 7 20 11 58 11 61 -1 77 7 68 32 -4 9 -9 10 -18 3
-6 -5 -15 -7 -18 -3 -4 3 -7 1 -7 -5 0 -9 -5 -9 -20 0 -16 10 -24 10 -41 0
-20 -13 -21 -12 -16 10 3 12 9 40 12 61 4 20 10 37 14 37 3 0 6 18 6 40 1 44
2 47 30 52 11 1 26 11 34 21 12 16 10 22 -14 47 -19 20 -25 34 -20 49 3 12 11
21 17 21 5 0 8 -7 4 -15 -7 -19 21 -20 40 -1 23 23 6 49 -27 42 -16 -3 -29 -1
-29 4 0 6 4 10 9 10 4 0 6 11 3 25 -2 15 0 25 7 25 7 0 9 5 6 10 -3 6 -2 10 4
10 5 0 9 9 8 21 -1 18 -3 18 -13 5 -6 -9 -23 -16 -37 -16 -14 0 -27 -6 -30
-12 -2 -7 -8 -10 -12 -6 -5 5 6 85 22 180 l31 172 -123 430 c-67 237 -143 505
-169 596 -25 91 -61 222 -81 292 -38 136 -42 157 -22 118 12 -23 80 -50 94
-37 3 3 -2 27 -11 53 -20 60 -20 76 0 72 10 -2 19 3 22 11 3 10 0 12 -11 8
-20 -8 -41 18 -34 43 3 12 1 17 -5 14 -5 -3 -8 0 -7 7 1 8 -8 36 -20 62 -24
52 -24 61 2 51 9 -3 13 -12 10 -22 -5 -13 -3 -14 6 -6 16 15 -2 54 -25 54 -13
0 -34 37 -34 59 0 5 12 6 26 3 24 -4 25 -3 13 16 -7 11 -15 20 -18 20 -20 -2
-31 3 -27 11 4 5 2 11 -2 13 -10 3 -52 136 -52 161 0 20 -38 17 -70 -6 -38
-26 -53 -22 -45 13 4 17 5 29 3 29 -2 -1 -11 -3 -20 -6z m625 -2971 c-2 -10 2
-24 11 -31 8 -7 13 -18 10 -25 -2 -6 5 -21 16 -33 24 -27 25 -35 4 -31 -10 2
-19 -2 -21 -8 -4 -13 29 -24 67 -23 17 1 19 3 8 6 -10 2 -18 14 -18 25 0 20 9
21 52 3 17 -7 17 -5 3 21 -8 16 -15 35 -15 42 0 6 -5 12 -11 12 -6 0 -9 -9 -6
-20 6 -21 -11 -39 -22 -23 -3 6 -13 13 -21 16 -11 4 -10 6 4 6 15 1 16 4 6 16
-27 33 38 56 82 29 13 -9 9 -24 -7 -24 -8 0 -15 -4 -15 -10 0 -11 28 -9 48 4
15 9 45 -12 36 -25 -3 -5 -19 -9 -35 -9 -17 0 -28 -4 -24 -10 3 -5 12 -7 20
-3 11 3 15 -2 15 -17 0 -15 -4 -20 -15 -17 -8 4 -17 1 -21 -5 -4 -7 -3 -8 4
-4 7 4 12 3 12 -3 0 -26 -66 -31 -153 -10 -39 9 -46 14 -36 25 6 8 9 14 6 14
-3 0 -13 3 -21 6 -11 4 -15 2 -11 -8 4 -9 -2 -14 -14 -15 -15 -1 -19 4 -17 25
3 21 8 26 25 24 11 -2 21 2 21 8 0 13 -50 10 -84 -5 -16 -7 -17 -5 -11 16 10
32 12 34 28 21 11 -9 19 -6 37 13 13 13 27 22 32 19 4 -3 11 2 14 10 9 22 23
20 17 -2z m219 -108 c-3 -17 -8 -22 -15 -15 -11 11 -3 41 11 41 5 0 7 -12 4
-26z"/>
<path d="M1603 5078 c5 -29 24 -34 48 -12 17 16 22 16 30 4 9 -13 10 -13 6 0
-6 22 -37 24 -52 3 -14 -17 -14 -17 -15 5 0 12 -4 22 -10 22 -5 0 -8 -10 -7
-22z"/>
<path d="M1500 5076 c0 -3 5 -17 10 -32 8 -21 8 -29 -2 -36 -9 -5 -6 -8 10 -8
19 0 22 4 18 20 -3 11 -9 29 -12 40 -4 17 -24 29 -24 16z"/>
<path d="M1696 5065 c-3 -9 -11 -15 -18 -14 -27 3 -37 -2 -43 -21 -3 -11 -12
-20 -19 -20 -10 0 -52 -70 -44 -74 2 0 13 -7 25 -13 24 -14 46 -18 27 -5 -16
10 -28 52 -15 52 6 0 11 7 11 15 0 11 7 14 24 9 14 -3 27 -1 31 7 6 9 2 11
-13 7 -28 -8 -29 8 -2 17 12 4 27 1 35 -5 8 -6 19 -9 24 -5 12 7 73 -34 66
-45 -6 -10 29 -8 43 3 20 16 14 22 -20 22 -27 0 -34 4 -36 23 -3 18 -9 23 -22
19 -17 -4 -36 16 -32 36 3 14 -16 7 -22 -8z"/>
<path d="M1598 5033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1890 5030 c0 -6 7 -13 15 -16 8 -4 12 -10 9 -16 -3 -5 -3 -17 1 -26
7 -14 9 -13 15 8 4 18 1 30 -13 43 -23 20 -27 21 -27 7z"/>
<path d="M1975 5010 c-11 -5 -14 -9 -7 -9 7 -1 11 -5 8 -10 -4 -5 2 -7 13 -4
15 4 19 -1 19 -21 0 -33 -17 -44 -33 -22 -16 21 -45 11 -45 -15 0 -10 5 -19
10 -19 6 0 10 5 10 12 0 22 18 -6 24 -37 5 -25 0 -36 -30 -67 -19 -21 -32 -38
-28 -38 5 0 3 -5 -3 -11 -14 -14 -82 -19 -110 -8 -14 5 -23 5 -23 -1 0 -6 9
-10 20 -10 24 0 26 -13 3 -31 -9 -8 -14 -9 -10 -2 5 7 1 9 -11 6 -11 -2 -26 2
-35 11 -14 14 -16 14 -21 0 -10 -26 -7 -46 7 -41 6 2 11 9 10 15 -4 17 37 1
47 -19 6 -11 16 -16 26 -12 12 5 13 7 3 14 -11 6 -10 9 3 14 9 4 14 13 12 20
-7 18 7 24 31 14 11 -4 14 -8 6 -8 -11 -1 -11 -4 -1 -15 6 -8 18 -12 26 -9 8
3 14 0 14 -6 0 -6 -5 -11 -11 -11 -6 0 -8 -9 -4 -21 6 -18 4 -19 -12 -11 -23
13 -87 -33 -65 -47 11 -6 10 -10 -5 -16 -15 -7 -14 -9 11 -14 31 -6 48 -31 22
-31 -9 0 -16 -4 -16 -9 0 -5 -15 -18 -32 -29 -30 -19 -31 -22 -13 -28 34 -12
55 -36 55 -63 1 -25 1 -25 15 -7 8 11 15 28 15 38 0 16 3 17 23 7 21 -11 21
-11 6 7 -11 13 -14 26 -9 39 5 12 3 28 -6 41 -13 21 -10 34 9 34 5 0 7 -6 4
-14 -3 -8 3 -26 14 -40 11 -14 20 -28 19 -31 0 -8 8 -6 29 7 22 13 17 48 -8
48 -14 1 -14 2 2 11 14 8 16 14 7 24 -12 14 -76 15 -99 1 -6 -4 -11 -1 -11 8
0 9 6 16 13 16 8 0 7 4 -3 10 -13 9 -13 10 0 10 8 0 26 3 40 6 20 4 22 3 10
-5 -8 -6 -10 -11 -4 -11 22 0 38 29 25 44 -9 11 -9 15 1 18 10 4 10 8 -1 21
-7 10 -20 17 -27 17 -15 0 -19 12 -7 23 3 4 12 2 19 -4 8 -7 14 -7 19 1 3 5 1
10 -4 10 -6 0 -11 5 -11 10 0 6 12 10 28 10 21 0 24 -2 12 -10 -13 -8 -12 -10
3 -10 14 0 17 -5 12 -20 -4 -11 -2 -20 4 -20 6 0 11 -5 11 -11 0 -6 -9 -8 -20
-4 -16 5 -20 2 -20 -14 0 -17 6 -21 35 -21 25 0 35 4 35 16 0 9 -5 12 -12 8
-8 -5 -9 -2 -5 9 3 10 2 17 -3 17 -6 0 -10 5 -10 10 0 15 27 12 44 -5 8 -8 19
-15 25 -15 6 0 11 -6 11 -12 0 -7 9 0 20 16 19 29 19 30 0 43 -15 11 -25 11
-41 3 -17 -10 -23 -8 -35 7 -7 10 -14 14 -14 10 0 -4 -10 1 -22 13 -21 19 -21
21 -5 37 15 15 17 15 26 -10 10 -25 31 -38 31 -19 0 5 14 9 30 9 32 0 53 13
33 20 -7 3 -13 9 -13 14 0 6 5 7 11 3 7 -4 10 -1 7 7 -3 8 -12 13 -20 11 -17
-3 -23 53 -7 63 12 8 12 25 0 25 -5 0 -14 -15 -21 -34 -9 -27 -16 -33 -37 -33
-25 0 -26 0 -8 13 15 11 19 25 18 59 -2 42 -1 44 17 35 17 -9 20 -7 20 10 0
12 -4 19 -9 15 -5 -3 -12 -1 -16 5 -7 12 -32 12 -60 0z"/>
<path d="M1690 4972 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22 -8
7 -15 8 -15 2z"/>
<path d="M1735 4960 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23 -10z"/>
<path d="M1540 4950 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"/>
<path d="M1666 4951 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M1804 4915 c-4 -11 -9 -24 -12 -30 -3 -5 -1 -7 3 -2 5 4 18 0 31 -8
20 -13 26 -13 54 1 25 13 29 19 19 30 -18 22 -42 18 -34 -6 4 -11 2 -20 -4
-20 -6 0 -11 5 -11 12 0 6 -3 8 -7 4 -4 -3 -12 4 -19 16 -12 22 -13 22 -20 3z"/>
<path d="M1740 4906 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M1780 4863 c-14 -8 -18 -12 -9 -10 17 5 43 -28 34 -43 -4 -6 2 -10
12 -10 10 0 27 -6 38 -14 18 -12 20 -11 21 8 0 12 -14 34 -35 53 -34 29 -38
30 -61 16z"/>
<path d="M1885 4861 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M2621 4856 c-45 -11 -53 -19 -71 -69 -9 -25 -14 -27 -42 -23 -21 4
-46 -1 -67 -12 -34 -16 -37 -24 -109 -240 -42 -122 -73 -224 -71 -226 2 -2 83
-16 179 -30 96 -15 237 -38 313 -53 123 -23 138 -28 134 -44 -3 -12 2 -20 13
-24 22 -7 41 -64 19 -57 -5 1 -9 -5 -9 -13 0 -8 4 -15 9 -15 5 0 16 -3 25 -6
23 -9 31 22 12 41 -21 21 -30 59 -22 91 8 30 26 27 43 -7 7 -13 20 -24 30 -25
10 -1 26 -5 36 -9 10 -4 21 -5 24 -1 4 3 -1 6 -10 6 -9 0 -17 7 -17 16 0 8 4
13 9 10 5 -3 12 1 15 9 5 15 -13 15 -61 -1 -8 -2 -13 6 -13 21 0 14 3 25 7 25
23 0 241 -65 237 -71 -2 -4 4 -5 14 -2 25 6 132 -25 156 -46 11 -9 16 -10 12
-3 -8 14 10 17 19 3 3 -5 -4 -13 -16 -16 -11 -4 -18 -9 -15 -12 3 -3 0 -15 -6
-26 -9 -17 -8 -19 7 -14 9 4 19 7 21 7 2 0 4 7 4 15 0 8 9 19 19 25 32 17 35
-6 6 -44 l-26 -35 38 -38 c132 -132 164 -345 124 -823 -31 -369 -49 -495 -79
-555 -37 -75 -99 -162 -132 -186 -17 -13 -26 -28 -23 -38 4 -14 3 -14 -9 -1
-15 17 -61 15 -55 -3 2 -7 22 -11 50 -10 38 1 47 -2 53 -19 4 -13 0 -24 -13
-33 -10 -7 -15 -17 -10 -21 4 -4 7 -2 7 4 0 6 7 9 17 5 11 -4 14 -3 9 6 -6 9
-1 10 18 5 14 -3 26 -3 26 1 0 16 -22 45 -35 45 -12 0 -12 6 -1 37 15 43 31
53 39 22 4 -14 10 -18 19 -13 7 4 6 -1 -3 -12 -24 -28 -20 -54 7 -54 13 0 26
-4 29 -10 3 -6 -4 -10 -17 -10 -29 0 -52 -44 -37 -71 7 -14 6 -19 -2 -19 -8 0
-6 -7 4 -18 10 -10 14 -23 10 -27 -5 -5 0 -3 11 5 10 8 14 16 7 18 -26 9 -9
29 25 30 49 2 51 6 18 37 -28 26 -28 26 -6 32 12 3 22 8 22 12 0 14 -29 46
-41 46 -7 0 -14 6 -17 14 -3 10 1 12 17 8 15 -4 20 -2 17 6 -3 7 -9 20 -12 30
-7 20 -21 22 -26 5 -3 -8 -7 -6 -11 5 -4 11 3 23 24 38 36 25 43 21 30 -15 -9
-22 -8 -26 8 -26 13 0 22 -10 26 -30 4 -17 11 -30 17 -30 6 0 8 -11 5 -26 -3
-20 1 -27 20 -35 35 -13 38 -11 18 11 -10 11 -14 20 -10 20 4 0 0 9 -11 21
-10 12 -14 23 -9 27 21 15 16 41 -8 47 -13 4 -24 12 -24 18 0 7 -4 24 -8 38
-7 27 14 56 23 30 3 -9 7 -9 17 -1 8 7 15 7 19 1 4 -5 -3 -12 -14 -16 -23 -7
-26 -21 -6 -28 8 -3 26 3 39 14 26 20 67 26 67 10 0 -5 -7 -12 -16 -15 -13 -5
-14 -9 -4 -21 7 -9 10 -19 6 -22 -4 -4 -11 -1 -16 7 -5 8 -12 12 -15 9 -9 -10
6 -39 20 -39 7 0 15 -5 17 -12 5 -14 38 -4 38 12 0 5 -7 10 -15 10 -20 0 -19
12 5 40 11 12 26 20 33 17 14 -5 41 18 44 38 1 5 7 11 13 13 9 3 10 -5 5 -29
-4 -19 -4 -31 0 -27 4 4 16 -2 27 -12 11 -10 26 -16 34 -13 9 3 14 -2 14 -15
0 -16 5 -19 23 -15 21 6 21 6 -3 -15 -14 -12 -19 -21 -12 -22 17 0 -7 -38 -30
-45 -23 -8 -33 -37 -10 -34 32 3 35 -1 18 -20 -9 -10 -16 -25 -14 -32 2 -8 -3
-13 -11 -11 -12 3 -12 0 -2 -12 7 -9 17 -13 22 -10 17 10 9 -15 -8 -26 -17
-11 -17 -11 0 -6 9 3 17 1 17 -4 0 -6 -7 -10 -15 -10 -9 0 -12 -6 -9 -15 4 -8
1 -15 -6 -15 -6 0 -8 -4 -5 -10 3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -1 10 6
10 6 0 9 -8 6 -19 -4 -17 -2 -18 14 -8 10 6 19 18 19 25 0 7 -4 11 -9 7 -14
-8 -21 9 -11 25 4 7 4 24 -1 38 -11 29 3 52 32 52 21 0 25 15 7 24 -7 3 -3 3
10 0 13 -3 22 -1 22 7 0 6 8 18 17 25 10 7 14 16 10 20 -4 5 -12 3 -16 -4 -4
-7 -15 -10 -24 -6 -18 7 -23 24 -7 24 6 0 8 20 5 47 -3 33 -1 43 7 35 6 -6 17
-8 24 -5 19 7 -1 33 -24 33 -9 0 -27 12 -40 28 -13 15 -21 22 -18 16 4 -7 -6
-14 -24 -16 -27 -4 -29 -3 -15 12 17 19 20 50 5 50 -5 0 -10 -4 -10 -10 0 -20
-29 -10 -50 18 -20 25 -21 25 -17 5 4 -18 1 -23 -14 -23 -12 0 -19 -7 -19 -18
0 -19 -18 -42 -33 -42 -4 0 -5 5 -2 11 4 5 -1 19 -10 29 -13 14 -15 23 -7 32
10 13 12 18 12 38 0 6 6 10 13 8 16 -3 57 20 57 33 0 6 -9 8 -20 4 -11 -4 -20
-2 -20 4 0 6 4 11 9 11 5 0 7 9 4 20 -3 11 -9 20 -14 20 -5 0 -9 7 -9 16 0 14
-2 14 -15 0 -20 -20 -19 -36 3 -36 12 0 14 -3 4 -8 -7 -5 -11 -19 -10 -32 3
-19 -3 -25 -30 -31 -40 -8 -49 4 -27 38 12 18 19 22 25 13 6 -9 9 -9 15 0 4 7
2 16 -4 20 -9 5 -9 13 0 33 11 24 31 33 62 28 11 -2 8 6 -9 23 -9 9 -11 16 -4
16 6 0 8 5 5 10 -11 17 -25 11 -25 -12 0 -12 -2 -19 -5 -16 -3 3 1 59 9 124
38 295 58 588 53 749 -6 173 -20 248 -64 341 -77 163 -224 232 -723 340 l-155
33 90 -6 c111 -7 109 -6 116 -16 10 -14 49 -16 49 -3 0 8 10 17 23 21 17 5 18
8 7 12 -8 3 -18 11 -22 17 -5 8 -11 7 -20 -2 -9 -9 -9 -12 1 -12 7 0 9 -5 6
-10 -4 -7 -13 -7 -25 0 -11 6 -23 10 -27 9 -4 -1 -19 -1 -32 0 -24 1 -24 2 -6
15 18 13 18 14 -8 9 -15 -3 -27 -1 -27 3 0 5 17 9 38 9 32 0 37 3 35 23 -2 12
2 22 7 22 6 0 10 -4 10 -9 0 -15 49 -19 61 -5 9 11 8 14 -1 14 -7 0 -10 6 -7
14 3 8 1 17 -6 19 -7 2 -8 8 -4 13 15 16 40 -12 42 -46 3 -47 15 -90 26 -90 5
0 9 -5 9 -11 0 -7 -10 -9 -24 -5 -14 3 -27 2 -30 -3 -4 -5 9 -8 26 -7 18 1 39
-2 46 -8 7 -5 15 -6 19 -2 3 3 1 6 -5 6 -7 0 -12 9 -12 20 0 11 5 20 11 20 6
0 1 9 -12 20 -13 10 -22 20 -19 20 3 1 25 3 49 5 32 3 41 1 32 -8 -16 -16 -14
-32 2 -18 8 6 17 8 20 4 4 -3 7 2 7 13 0 19 -30 49 -45 45 -4 -1 -13 6 -21 16
-11 14 -13 14 -9 2 4 -10 -3 -22 -19 -33 -22 -14 -26 -14 -26 -2 0 8 4 18 10
21 5 3 14 14 20 24 7 14 3 23 -18 42 -15 13 -24 20 -20 15 8 -9 -9 -36 -22
-36 -13 0 -19 40 -7 47 7 4 25 8 40 9 31 2 49 14 30 21 -9 3 -10 10 -4 21 6
12 4 22 -7 32 -14 13 -15 12 -9 -3 4 -11 1 -17 -9 -17 -18 0 -12 47 10 68 13
13 17 13 32 1 16 -13 17 -12 11 5 -5 13 0 27 15 43 19 20 20 25 8 33 -12 7
-17 2 -23 -20 -5 -22 -14 -30 -30 -30 -23 0 -47 -37 -47 -74 0 -15 -3 -17 -11
-9 -6 6 -19 9 -28 6 -9 -3 -8 0 4 8 30 18 31 19 16 28 -10 6 -9 12 4 25 9 9
12 16 6 16 -6 0 -11 7 -11 16 0 14 3 14 15 4 13 -11 20 -8 40 14 21 23 22 27
6 21 -11 -3 -25 1 -34 10 -19 19 -31 19 -47 1 -6 -8 -19 -12 -27 -9 -13 5 -15
2 -10 -11 4 -10 7 -19 7 -21 0 -2 8 -1 18 2 16 4 18 0 14 -26 -5 -35 -21 -41
-29 -11 -4 16 -8 18 -19 9 -19 -16 -31 16 -14 36 10 12 10 15 -3 15 -9 0 -16
8 -16 18 -3 64 -5 68 -30 53 -10 -6 -10 -10 -1 -16 6 -4 9 -13 6 -20 -3 -8 1
-19 9 -25 8 -6 11 -18 8 -26 -3 -8 2 -23 11 -33 10 -11 14 -25 9 -37 -6 -16
-4 -17 8 -7 12 10 17 7 28 -17 11 -27 10 -32 -16 -65 -15 -19 -32 -35 -36 -35
-5 0 -6 -4 -3 -9 7 -11 -41 -24 -54 -16 -6 4 -10 1 -10 -5 0 -7 -7 -9 -15 -6
-8 3 -22 9 -30 12 -8 3 -15 1 -15 -4 0 -6 10 -12 23 -15 20 -3 21 -4 3 -12
-11 -5 -31 -4 -47 2 -22 9 -29 8 -33 -4 -9 -24 -7 -33 9 -33 9 0 12 6 9 15 -5
13 -1 12 25 -2 17 -10 31 -21 31 -25 0 -4 7 -5 16 -1 13 5 13 7 2 14 -10 7 -9
9 5 9 11 0 17 6 15 16 -3 19 28 28 39 12 3 -7 1 -8 -5 -4 -7 4 -12 3 -12 -2 0
-13 25 -33 34 -27 3 3 2 0 -4 -7 -6 -8 -6 -16 1 -25 13 -15 5 -17 -21 -3 -13
7 -19 6 -24 -5 -3 -8 -12 -15 -21 -15 -8 0 -15 -4 -15 -10 0 -13 -70 -13 -90
0 -13 9 -13 11 0 19 8 6 11 14 6 18 -4 4 -13 1 -19 -8 -25 -34 -69 -24 -59 13
3 12 -1 18 -13 18 -13 0 -16 -6 -13 -25 3 -14 1 -25 -3 -25 -5 0 -9 7 -9 15 0
9 -9 15 -22 15 -19 0 -20 2 -8 10 13 8 12 10 -2 10 -10 0 -18 -5 -18 -11 0 -5
-5 -7 -10 -4 -6 4 -8 11 -5 16 4 5 1 9 -5 9 -7 0 -9 12 -5 34 5 25 3 35 -9 39
-8 3 -18 2 -21 -4 -4 -5 -15 -3 -27 6 -16 11 -25 12 -35 3 -20 -16 -17 21 6
66 11 21 18 43 15 50 -3 7 2 19 10 26 9 7 13 16 10 21 -7 11 24 110 39 124 7
7 8 4 3 -9 -7 -20 -7 -20 15 0 11 10 24 19 28 19 3 0 5 6 5 12 -1 7 1 11 5 9
3 -2 13 3 20 12 13 14 13 15 -7 10 -11 -3 -22 -3 -24 2 -2 4 -23 2 -47 -4z
m469 -339 c-1 -31 -1 -31 -13 -9 -7 12 -16 19 -20 16 -4 -4 -7 -3 -7 2 0 15
22 34 32 28 4 -3 8 -20 8 -37z m-36 -16 c4 -5 0 -12 -6 -14 -8 -3 -6 -6 4 -6
11 -1 15 -6 12 -16 -4 -8 -1 -15 5 -15 6 0 11 -4 11 -10 0 -5 -10 -8 -22 -6
-13 3 -27 5 -32 5 -4 1 -6 10 -3 21 3 10 0 21 -6 23 -14 4 1 27 18 27 8 0 16
-4 19 -9z m-494 -21 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z m30 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4
-4 4 -10z m40 0 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20 0z
m380 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-474 -15 c8 -8 21 -12 29 -9 10 4 12 0 8 -15 -3 -11 -9 -18 -14 -15 -5
3 -9 1 -9 -4 0 -5 -11 -7 -25 -4 -27 5 -28 6 -19 40 7 26 11 27 30 7z m614
-25 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-680 -46 c0 -2 -11 -4 -25 -4 -14 0 -25 4 -25 9 0 4 11 6 25 3 14 -2 25 -6
25 -8z m220 12 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10
-2 10 -4z m-153 -22 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m76 -11 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m70 -10 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m60 -10 c-7 -2 -21 -2 -30
0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m65 -10 c-10 -2 -26 -2 -35 0 -10 3 -2 5
17 5 19 0 27 -2 18 -5z m172 -118 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8
5 15 10 15 6 0 10 -7 10 -15z m640 -1653 c0 -12 -41 -54 -47 -49 -6 7 27 57
38 57 5 0 9 -4 9 -8z m40 -12 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z m-15 -20 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0
6 4 10 9 10 6 0 13 -4 16 -10z m125 -30 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-360 -104 c0 -2 -7 -9 -15 -16 -13 -11
-14 -10 -9 4 5 14 24 23 24 12z m170 -6 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10
3 6 8 10 11 10 2 0 4 -4 4 -10z m330 -85 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10
15 0 8 5 15 10 15 6 0 10 -7 10 -15z"/>
<path d="M1617 4840 c-3 -12 0 -20 7 -20 18 0 25 10 19 26 -7 20 -20 17 -26
-6z"/>
<path d="M1740 4848 c0 -4 5 -19 10 -33 9 -24 8 -26 -7 -21 -15 6 -16 5 -5 -6
10 -10 16 -8 34 9 21 22 21 22 2 29 -10 4 -22 12 -26 18 -4 6 -8 8 -8 4z"/>
<path d="M2715 4821 c3 -5 -5 -13 -17 -17 l-23 -7 23 -8 c24 -9 30 -29 10 -30
-7 0 -2 -5 12 -11 19 -8 21 -12 9 -15 -19 -5 -42 -50 -33 -65 4 -7 2 -8 -4 -4
-7 4 -12 2 -12 -3 0 -6 6 -11 14 -11 8 0 23 -3 33 -7 17 -6 17 -6 1 10 -23 24
-3 66 31 64 13 -1 26 4 29 11 3 11 0 12 -17 3 -12 -7 -21 -8 -21 -2 0 15 41
24 52 13 6 -6 17 -8 25 -5 14 6 60 -35 56 -49 -3 -9 27 -6 45 5 22 14 13 24
-18 19 -25 -4 -31 0 -40 23 -6 15 -13 25 -17 21 -8 -7 -33 13 -33 26 0 7 4 7
13 1 6 -6 20 -13 29 -16 15 -5 16 -2 7 19 -5 14 -7 30 -3 35 3 5 0 9 -5 9 -6
0 -11 -4 -11 -9 0 -5 -18 -9 -41 -9 -25 -1 -49 -8 -61 -19 -19 -17 -19 -17
-13 10 6 21 4 27 -10 27 -9 0 -14 -4 -10 -9z m75 -41 c0 -5 -9 -10 -19 -10
-11 0 -21 -6 -24 -12 -2 -7 -8 -10 -12 -5 -9 8 23 36 43 37 6 0 12 -4 12 -10z"/>
<path d="M2880 4802 c1 -21 19 -34 35 -25 18 10 15 15 -17 26 -10 4 -18 3 -18
-1z"/>
<path d="M1621 4779 c-20 -8 -22 -11 -10 -27 34 -42 68 -55 70 -24 0 10 3 12
6 5 7 -18 23 -16 23 2 0 8 -4 15 -10 15 -5 0 -6 8 -3 18 6 15 5 16 -13 2 -10
-8 -21 -18 -24 -22 -3 -4 -12 -3 -20 3 -13 8 -12 12 4 24 22 17 13 18 -23 4z"/>
<path d="M2635 4768 c-4 -9 -4 -21 -1 -27 3 -5 0 -12 -6 -14 -8 -4 -7 -6 4 -6
22 -1 26 8 18 39 -6 21 -8 22 -15 8z"/>
<path d="M3150 4770 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M3310 4731 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2115 4704 c4 -15 -2 -26 -21 -38 -14 -10 -27 -16 -28 -14 -2 2 -8
12 -14 23 -10 18 -11 15 -12 -17 0 -21 4 -38 9 -38 5 0 7 -8 5 -17 -3 -11 4
-23 16 -31 19 -12 22 -11 27 7 3 12 10 21 17 22 6 0 1 4 -11 9 -35 14 -30 41
6 34 23 -5 29 -2 34 15 3 12 2 21 -2 21 -5 0 -13 10 -20 23 -10 20 -11 21 -6
1z"/>
<path d="M3435 4700 c-5 -15 0 -26 17 -40 13 -11 26 -19 30 -20 5 0 14 -13 20
-28 13 -27 14 -28 31 -10 16 17 16 18 -3 18 -11 0 -24 8 -27 17 -4 9 -18 27
-32 40 -14 12 -20 23 -13 23 7 0 15 -6 18 -14 3 -8 15 -11 30 -8 13 2 25 -1
25 -7 1 -6 2 -16 3 -23 0 -6 7 -13 15 -16 10 -3 11 2 6 18 -4 14 -3 20 3 16 5
-3 13 1 16 10 5 12 1 14 -16 8 -21 -6 -22 -5 -8 11 14 17 13 18 -22 11 -22 -4
-39 -2 -43 4 -11 17 -43 11 -50 -10z"/>
<path d="M2800 4693 c0 -6 5 -15 10 -18 6 -4 8 -10 5 -15 -3 -5 8 -2 25 6 17
9 28 20 25 24 -7 12 -35 1 -35 -13 0 -6 -7 -2 -15 9 -8 10 -15 14 -15 7z"/>
<path d="M1610 4669 c0 -24 23 -21 28 4 2 10 -3 17 -12 17 -10 0 -16 -9 -16
-21z"/>
<path d="M2656 4675 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M3380 4678 c0 -4 7 -8 15 -8 8 0 15 4 15 8 0 5 -7 9 -15 9 -8 0 -15
-4 -15 -9z"/>
<path d="M1750 4671 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2770 4670 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1687 4650 c-16 -19 -16 -20 4 -20 11 0 17 5 14 10 -3 6 1 10 9 10 9
0 16 5 16 10 0 17 -25 11 -43 -10z"/>
<path d="M2910 4645 c-12 -22 -11 -27 6 -40 22 -16 40 -19 48 -6 3 4 -6 13
-20 20 -21 9 -24 14 -15 25 7 8 8 17 3 20 -5 3 -15 -6 -22 -19z"/>
<path d="M3610 4655 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z"/>
<path d="M2592 4648 c4 -8 1 -21 -5 -27 -17 -17 1 -25 29 -12 20 9 25 9 22 -1
-6 -16 16 -38 36 -38 14 0 14 3 4 20 -7 10 -14 17 -16 15 -2 -2 -12 4 -22 13
-48 43 -54 47 -48 30z"/>
<path d="M3366 4630 c-11 -24 -12 -30 -1 -30 7 0 15 8 19 18 3 9 9 23 12 30
13 27 -16 10 -30 -18z"/>
<path d="M2844 4635 c3 -21 16 -31 16 -12 0 9 -4 18 -9 21 -5 4 -8 -1 -7 -9z"/>
<path d="M2953 4635 c0 -16 24 -28 38 -19 12 7 -8 34 -26 34 -6 0 -12 -7 -12
-15z"/>
<path d="M3267 4643 c-15 -23 -16 -94 -1 -100 17 -6 18 -30 2 -36 -10 -3 -10
-7 -1 -17 7 -8 19 -10 30 -5 10 5 21 8 25 7 7 -2 2 44 -8 76 -2 8 4 10 21 6
15 -4 25 -2 25 5 0 6 -7 8 -17 4 -10 -4 -14 -2 -10 4 4 6 -1 14 -10 18 -10 3
-19 1 -21 -4 -7 -22 -21 -10 -24 22 -2 19 -6 27 -11 20z"/>
<path d="M2785 4630 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3585 4630 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M3640 4630 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3320 4620 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M3617 4623 c-4 -3 -7 -15 -7 -26 0 -25 13 -14 15 13 2 22 1 23 -8 13z"/>
<path d="M3763 4623 c13 -3 16 -10 12 -24 -4 -11 -9 -36 -11 -56 -5 -36 -4
-37 16 -18 11 10 20 24 20 32 0 8 7 21 15 30 14 14 13 17 -11 29 -14 8 -34 13
-43 13 -13 -1 -13 -2 2 -6z"/>
<path d="M1975 4610 c-3 -5 -2 -10 3 -10 6 0 9 -7 8 -16 -2 -25 17 -27 31 -4
11 16 10 18 -2 14 -9 -4 -15 0 -15 10 0 18 -15 22 -25 6z"/>
<path d="M3662 4611 c-11 -6 -10 -11 3 -25 9 -9 22 -16 28 -16 8 0 8 3 -1 8
-7 5 -10 16 -6 25 7 18 -3 22 -24 8z"/>
<path d="M1678 4603 c-10 -2 -18 -12 -18 -21 0 -11 3 -13 8 -5 5 8 11 8 24 -1
10 -7 15 -16 11 -19 -3 -4 0 -7 8 -7 16 0 39 19 39 32 0 4 -7 8 -15 8 -8 0
-15 5 -15 10 0 10 -12 11 -42 3z"/>
<path d="M2711 4594 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3557 4595 c-9 -7 -17 -17 -17 -24 0 -14 -6 -14 -31 -1 -15 8 -19 8
-17 -2 2 -7 -5 -12 -14 -12 -10 1 -16 -3 -15 -8 1 -6 -3 -12 -10 -15 -6 -2
-13 6 -15 17 -3 22 -3 22 -25 3 -16 -14 -19 -23 -11 -31 6 -6 16 -8 23 -6 16
7 27 -66 12 -83 -13 -18 -27 -16 -27 2 0 8 -5 15 -11 15 -5 0 -8 -4 -5 -9 3
-5 -15 -14 -39 -21 -45 -13 -56 -24 -32 -33 9 -3 9 -9 -1 -26 -7 -12 -8 -21
-2 -21 5 0 10 5 10 10 0 6 11 8 25 4 16 -4 25 -2 25 5 0 8 5 9 19 1 17 -8 19
-7 16 8 -4 20 2 31 26 50 13 10 22 9 45 -3 28 -14 29 -14 34 12 5 25 6 25 20
8 11 -13 11 -16 2 -11 -10 6 -13 3 -10 -8 5 -28 23 -22 28 10 4 22 11 29 28
28 23 -1 29 10 12 21 -6 4 -17 2 -24 -5 -8 -6 -26 -8 -40 -4 -15 3 -26 2 -25
-2 1 -5 1 -17 0 -26 -1 -24 -31 -23 -31 0 0 10 -5 23 -11 29 -8 8 -7 17 6 35
l17 23 -7 -25 c-6 -24 -5 -24 9 -6 9 11 16 17 16 13 0 -4 9 2 20 13 11 11 20
29 20 39 0 21 16 37 22 21 1 -5 8 -6 14 -2 9 5 9 10 0 18 -9 8 -17 8 -29 -1z
m-168 -194 c8 -5 9 -11 3 -15 -5 -3 -15 1 -22 9 -14 16 -3 20 19 6z"/>
<path d="M2873 4593 c-7 -2 -13 -9 -13 -15 0 -6 6 -8 14 -5 16 6 43 -19 32
-30 -3 -4 8 -12 26 -18 23 -7 35 -7 40 0 12 15 9 25 -5 25 -8 0 -24 11 -37 25
-23 25 -33 28 -57 18z"/>
<path d="M2986 4591 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M2149 4572 c-15 -17 -15 -20 0 -29 14 -9 13 -13 -4 -39 -19 -30 -26
-84 -9 -84 5 0 10 5 10 10 1 6 2 14 3 20 0 5 7 7 15 4 8 -3 16 3 19 16 7 27
-3 91 -13 85 -5 -3 -6 4 -3 15 7 25 2 25 -18 2z"/>
<path d="M2727 4570 c-3 -13 0 -20 8 -20 21 0 27 16 11 29 -11 9 -15 7 -19 -9z"/>
<path d="M2810 4570 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3726 4565 c4 -8 7 -15 9 -15 1 0 8 7 15 15 10 13 9 15 -8 15 -15 0
-20 -5 -16 -15z"/>
<path d="M2620 4560 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2849 4552 c14 -27 14 -35 -1 -26 -10 6 -10 4 -1 -6 11 -12 15 -11
28 6 17 22 19 34 6 34 -5 0 -17 3 -26 7 -15 5 -16 3 -6 -15z"/>
<path d="M1760 4549 c0 -4 9 -9 20 -12 11 -3 20 -1 20 5 0 5 6 6 13 2 6 -4 9
-3 4 1 -9 10 -57 13 -57 4z"/>
<path d="M2565 4550 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M2670 4549 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3573 4553 c-7 -3 -13 -11 -13 -19 0 -16 9 -18 27 -6 15 9 3 31 -14
25z"/>
<path d="M3615 4550 c-3 -5 -4 -14 0 -19 3 -6 -3 -14 -12 -20 -14 -8 -15 -12
-6 -22 10 -10 15 -9 28 1 13 11 15 8 15 -19 0 -19 -4 -30 -10 -26 -6 4 -7 -1
-3 -11 5 -13 3 -16 -10 -11 -11 4 -17 1 -17 -8 0 -8 5 -15 11 -15 8 0 7 -5 -2
-16 -10 -13 -10 -17 2 -25 8 -5 19 -9 26 -9 7 0 10 -7 7 -15 -4 -8 -1 -15 5
-15 17 0 13 -17 -5 -24 -9 -4 -22 -2 -29 4 -6 5 -16 6 -21 2 -5 -4 -1 2 9 14
23 28 22 29 -15 32 -18 1 -28 -1 -22 -5 6 -5 10 -21 10 -37 -1 -16 5 -32 14
-36 8 -4 25 -12 37 -18 17 -9 24 -8 34 4 6 8 14 12 17 9 3 -3 -3 -14 -13 -25
-16 -17 -16 -20 -2 -20 9 0 19 5 22 10 4 6 11 7 17 4 6 -4 -2 -12 -18 -20 -19
-8 -23 -13 -11 -13 19 -1 24 -21 5 -21 -9 0 -9 -3 0 -12 16 -16 15 -28 -3 -28
-21 0 -19 -10 7 -37 20 -21 22 -21 34 -5 19 25 17 34 -5 22 -24 -12 -31 -13
-31 -1 0 11 49 21 67 14 8 -3 12 -12 8 -21 -3 -8 -1 -20 5 -27 8 -9 6 -17 -4
-28 -13 -13 -12 -14 13 -13 31 1 67 31 51 41 -5 3 -13 4 -17 2 -5 -3 -8 6 -8
19 0 13 5 26 12 28 7 3 10 -1 6 -10 -3 -8 1 -14 10 -14 12 0 27 19 27 34 0 7
-38 3 -57 -6 -29 -14 -50 6 -45 44 3 20 0 28 -11 28 -18 0 -33 33 -18 38 6 2
11 11 11 19 0 10 6 13 16 9 12 -5 15 -2 10 10 -3 8 -6 18 -6 20 0 3 -4 2 -10
-1 -5 -3 -10 3 -10 13 0 27 -27 49 -42 34 -8 -8 -8 -15 2 -26 14 -18 7 -46
-13 -46 -9 0 -13 12 -13 33 0 17 -4 40 -8 50 -7 16 -5 18 15 16 35 -4 67 9 74
32 4 10 10 19 15 19 5 0 8 16 7 35 -2 31 -4 33 -14 18 -7 -9 -16 -13 -20 -8
-5 4 -2 10 5 12 19 7 14 41 -9 54 -22 12 -25 10 -50 -33 -8 -15 -6 -18 9 -18
13 0 21 -9 25 -27 7 -37 3 -39 -27 -16 -18 12 -25 25 -23 40 3 16 -2 24 -15
29 -11 3 -17 12 -14 20 6 16 -10 29 -19 14z m83 -154 c3 -15 1 -16 -17 -6 -12
6 -19 16 -15 21 9 15 28 5 32 -15z m22 -236 c0 -5 -2 -10 -4 -10 -3 0 -8 5
-11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M2045 4540 c-14 -23 -13 -49 2 -43 7 3 13 -2 13 -11 0 -9 7 -19 15
-22 23 -9 18 -24 -9 -24 -19 0 -23 -4 -19 -20 6 -21 23 -28 23 -9 0 6 9 9 20
6 11 -3 18 -1 15 4 -3 5 -1 17 4 28 9 16 7 20 -12 25 -17 4 -26 16 -33 43 -7
29 -11 35 -19 23z"/>
<path d="M1720 4530 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M1644 4523 c-19 -7 8 -55 28 -49 11 2 15 -1 11 -10 -3 -8 1 -14 11
-14 9 0 16 8 16 20 0 15 -7 20 -24 20 -16 0 -26 6 -28 18 -2 11 -8 17 -14 15z"/>
<path d="M3893 4517 c4 -11 4 -24 0 -30 -12 -20 -10 -47 4 -47 8 0 13 -6 12
-12 -3 -25 1 -39 10 -34 5 3 14 -1 21 -9 10 -12 9 -15 -5 -15 -10 0 -15 -5
-12 -13 3 -7 -4 -19 -14 -25 -22 -14 -26 -34 -4 -26 9 4 15 0 16 -7 1 -8 2
-23 3 -34 1 -15 9 -21 35 -23 23 -2 31 0 26 8 -4 6 -13 9 -21 6 -9 -3 -14 2
-14 14 0 11 4 20 9 20 5 0 16 3 25 6 10 4 26 -6 45 -27 34 -37 76 -48 68 -17
-4 15 -1 17 21 12 19 -4 23 -3 13 4 -9 7 -10 12 -2 16 6 4 11 13 11 19 0 8 -3
8 -9 -1 -7 -11 -9 -11 -14 1 -3 9 -14 13 -31 10 -21 -4 -24 -2 -22 18 1 13 8
34 15 47 6 12 9 28 5 34 -5 8 -9 7 -13 -5 -6 -15 -9 -15 -34 -2 -23 12 -27 12
-27 0 0 -20 -21 -30 -31 -14 -14 22 -10 49 7 49 11 0 14 6 9 23 -5 17 -4 18 4
7 5 -8 13 -11 17 -7 4 3 1 13 -6 22 -13 16 -31 20 -29 8 3 -21 -3 -33 -17 -33
-8 0 -13 4 -10 8 5 8 -3 16 -49 52 -17 14 -18 13 -12 -3z m37 -42 c0 -8 -4
-15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z m13 -45 c-3 -11 -7 -20 -9
-20 -2 0 -4 9 -4 20 0 11 4 20 9 20 5 0 7 -9 4 -20z m96 -83 c6 -8 8 -17 4
-20 -11 -12 -23 -8 -23 8 0 13 -5 12 -22 -5 -15 -13 -24 -17 -27 -9 -2 8 -6 7
-12 -2 -7 -12 -9 -12 -9 0 0 33 67 54 89 28z m66 -57 c-3 -5 -11 -10 -16 -10
-6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z"/>
<path d="M2665 4511 c-3 -6 4 -15 15 -21 11 -6 18 -15 15 -21 -4 -5 -1 -9 4
-9 6 0 11 7 11 15 0 20 16 19 24 -1 4 -10 12 -14 22 -10 12 5 15 2 10 -10 -12
-30 16 -8 30 24 14 34 5 42 -20 17 -27 -28 -50 -14 -23 14 10 12 8 13 -8 6
-41 -15 -55 -17 -55 -6 0 13 -17 15 -25 2z"/>
<path d="M3223 4493 c-7 -2 -13 -11 -13 -19 0 -12 4 -12 20 -4 32 17 26 37 -7
23z"/>
<path d="M1765 4470 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M2800 4470 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3240 4460 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M3311 4459 c-23 -9 -24 -11 -8 -26 13 -13 20 -14 28 -6 6 6 18 9 25
6 8 -3 14 1 14 10 0 11 -23 30 -33 26 -1 0 -13 -5 -26 -10z"/>
<path d="M4041 4446 c-13 -16 -2 -29 16 -18 7 4 8 13 4 20 -6 10 -10 10 -20
-2z"/>
<path d="M1645 4429 c-11 -16 3 -24 46 -25 32 0 37 2 27 14 -9 10 -13 11 -16
3 -3 -9 -11 -7 -27 3 -16 11 -26 12 -30 5z"/>
<path d="M1883 4405 c1 -19 2 -35 4 -35 9 0 22 41 17 54 -11 28 -22 17 -21
-19z"/>
<path d="M3776 4424 c-3 -9 -4 -20 0 -25 3 -5 -4 -9 -14 -9 -38 0 -44 -25 -15
-56 15 -16 31 -28 35 -26 5 1 12 -6 15 -15 12 -31 32 -46 47 -34 9 7 16 7 24
-1 9 -9 12 -9 12 1 0 8 6 11 17 6 15 -5 15 -3 -2 13 -11 11 -22 18 -24 16 -2
-2 -13 3 -23 11 -11 7 -26 11 -33 9 -6 -3 -18 2 -25 11 -11 13 -10 18 6 30 22
16 44 19 44 6 0 -5 11 -8 25 -7 14 0 25 3 25 7 0 12 -25 29 -43 29 -12 0 -16
4 -11 13 4 6 -2 4 -13 -5 -28 -25 -36 -22 -31 12 5 32 -6 41 -16 14z"/>
<path d="M3835 4430 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M1974 4416 c-3 -7 -4 -19 -2 -27 2 -8 -2 -13 -9 -12 -7 2 -17 -7 -23
-20 -9 -19 -9 -20 2 -6 15 18 38 14 38 -6 0 -8 -8 -19 -17 -24 -16 -9 -16 -10
3 -11 12 0 27 6 34 14 6 8 18 12 27 9 11 -4 12 -8 2 -13 -9 -6 -6 -15 11 -37
l22 -28 -7 33 c-7 33 2 41 23 20 9 -9 12 -8 12 5 0 11 -8 17 -22 17 -27 0 -94
65 -79 75 6 3 11 10 11 16 0 14 -20 11 -26 -5z"/>
<path d="M4107 4401 c-3 -14 0 -17 14 -14 15 4 17 8 8 19 -15 18 -16 18 -22
-5z"/>
<path d="M1760 4404 c0 -4 7 -12 15 -18 10 -7 12 -16 5 -26 -7 -12 -12 -11
-28 8 -22 25 -32 28 -32 8 0 -7 9 -16 20 -19 14 -3 20 -14 20 -31 0 -28 -18
-34 -38 -14 -9 9 -12 8 -12 -5 0 -9 5 -17 10 -17 19 0 10 -18 -12 -23 -17 -5
-19 -9 -10 -20 7 -8 8 -18 4 -22 -4 -5 -1 -5 6 -1 13 8 52 -19 52 -36 0 -13
-38 -9 -44 5 -3 6 -5 3 -3 -7 1 -11 11 -20 22 -22 11 -2 21 -8 23 -13 2 -6 19
-13 37 -17 31 -5 33 -8 19 -22 -12 -11 -13 -17 -3 -23 9 -5 8 -11 -5 -24 -9
-10 -15 -24 -12 -31 3 -9 -2 -14 -14 -14 -26 0 -26 -16 1 -30 16 -9 19 -17 14
-41 -8 -34 5 -38 24 -8 11 18 15 19 23 8 8 -11 9 -10 4 4 -3 10 -6 23 -6 28 0
5 -7 9 -15 9 -9 0 -15 9 -15 21 0 18 2 19 15 9 9 -7 18 -11 20 -8 10 9 -7 38
-22 38 -8 0 -11 5 -8 10 4 6 13 9 20 8 7 -2 25 10 39 27 26 30 21 45 -16 45
-10 0 -18 4 -18 9 0 5 -3 17 -6 25 -5 13 -2 15 12 9 17 -6 17 -5 5 10 -11 15
-11 17 2 17 8 0 21 8 28 18 10 13 10 15 0 5 -9 -7 -23 -8 -42 -3 -27 8 -31 6
-40 -18 -9 -24 -11 -25 -20 -9 -6 9 -17 17 -24 17 -21 0 -18 18 5 32 14 9 18
19 14 35 -4 13 -1 30 6 38 10 12 15 10 35 -11 13 -14 26 -21 29 -16 3 6 -1 15
-10 22 -10 9 -13 22 -9 42 7 28 1 33 -32 29 -7 -1 -13 3 -13 9 0 5 -4 10 -10
10 -5 0 -10 -3 -10 -6z"/>
<path d="M2160 4402 c0 -4 -13 -16 -29 -26 -24 -16 -26 -22 -16 -33 7 -7 15
-18 18 -25 2 -7 8 -10 12 -6 4 4 3 14 -4 21 -14 18 -4 48 14 41 7 -3 16 -1 19
4 3 6 15 8 26 5 11 -3 20 0 20 6 0 6 -3 11 -7 12 -5 0 -18 2 -30 5 -13 2 -23
1 -23 -4z"/>
<path d="M2855 4400 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2090 4385 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M3475 4380 c-5 -17 -9 -19 -21 -9 -8 6 -17 9 -20 6 -3 -3 3 -10 15
-16 17 -9 22 -8 31 10 7 11 9 23 7 26 -3 2 -8 -5 -12 -17z"/>
<path d="M4143 4377 c-15 -18 -25 -37 -20 -37 12 0 47 39 39 44 -6 3 -14 0
-19 -7z"/>
<path d="M2035 4360 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>
<path d="M2201 4359 c-13 -5 -22 -11 -20 -14 8 -7 49 7 49 16 0 5 -1 9 -2 8
-2 0 -14 -5 -27 -10z"/>
<path d="M3498 4350 c-2 -16 -12 -27 -28 -31 -14 -4 -19 -7 -12 -8 7 -1 10 -5
7 -11 -3 -5 -2 -10 2 -10 5 0 14 9 21 20 7 12 18 20 25 19 27 -3 36 2 31 16
-4 8 -11 12 -16 9 -6 -4 -14 -1 -18 6 -6 9 -9 6 -12 -10z"/>
<path d="M1882 4330 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M1676 4338 c3 -4 12 -8 20 -8 8 0 14 4 14 8 0 4 -9 8 -19 8 -11 0
-17 -4 -15 -8z"/>
<path d="M3260 4335 c0 -9 -6 -12 -17 -8 -12 5 -15 2 -10 -10 4 -11 2 -14 -9
-10 -7 3 -14 1 -14 -5 0 -5 17 -13 38 -17 20 -4 46 -9 58 -11 15 -3 24 4 33
22 15 35 9 39 -28 20 -42 -22 -52 -20 -45 9 3 14 3 25 0 25 -3 0 -6 -7 -6 -15z"/>
<path d="M4153 4342 c-7 -4 -9 -13 -4 -21 5 -7 6 -21 4 -31 -5 -20 29 -36 49
-23 7 4 21 6 32 4 12 -2 17 2 14 11 -3 7 -12 12 -22 10 -9 -2 -16 2 -16 8 0 5
-9 10 -20 10 -23 0 -26 10 -8 28 9 9 9 12 -2 12 -8 0 -20 -4 -27 -8z"/>
<path d="M3386 4313 c-3 -7 -1 -13 4 -13 6 0 10 -9 10 -21 0 -28 29 -57 40
-40 11 20 0 51 -16 44 -8 -3 -14 0 -14 6 0 6 5 11 10 11 6 0 10 6 10 14 0 18
-38 17 -44 -1z"/>
<path d="M1686 4295 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M2096 4265 c10 -8 13 -15 7 -15 -10 0 -10 -6 -1 -25 10 -22 8 -29
-12 -50 -13 -14 -19 -25 -13 -25 6 0 14 7 17 15 4 12 10 13 24 6 10 -6 28 -9
40 -8 16 1 19 5 11 19 -5 9 -14 20 -19 23 -6 3 -10 17 -10 31 0 17 -8 27 -26
34 -35 14 -41 12 -18 -5z"/>
<path d="M2210 4259 c0 -18 2 -19 15 -9 13 11 14 10 9 -4 -3 -9 -11 -16 -18
-17 -6 0 -2 -4 9 -9 38 -16 43 -12 31 25 -12 40 -46 50 -46 14z"/>
<path d="M1910 4261 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M4206 4255 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M2160 4236 c0 -36 17 -41 22 -6 3 20 0 30 -9 30 -7 0 -13 -11 -13
-24z"/>
<path d="M3600 4235 c0 -13 -4 -14 -21 -5 -27 15 -33 8 -11 -11 10 -9 25 -13
33 -10 15 7 22 41 8 41 -5 0 -9 -7 -9 -15z"/>
<path d="M3892 4242 c-25 -8 -12 -32 18 -32 17 0 30 -3 30 -6 0 -9 -43 -43
-55 -44 -5 0 -15 -11 -22 -25 -9 -20 -8 -25 3 -25 8 0 14 -6 14 -14 0 -15 18
-31 26 -24 2 3 -2 21 -11 40 -14 34 -13 36 4 31 10 -2 26 3 35 13 16 16 17 15
14 -17 -2 -19 2 -34 7 -34 6 0 9 8 7 19 -1 10 3 25 9 32 6 7 8 18 4 24 -3 5
-3 15 1 22 5 8 10 6 15 -8 7 -18 8 -18 8 3 1 12 7 23 14 26 6 3 -10 4 -36 2
-28 -1 -53 3 -60 10 -7 6 -18 10 -25 7z"/>
<path d="M4124 4236 c-11 -6 -19 -17 -16 -24 2 -7 8 -10 13 -6 5 3 9 -2 9 -10
0 -9 5 -16 10 -16 6 0 10 -8 10 -17 0 -10 -4 -14 -8 -8 -4 6 -16 15 -27 21
-11 6 -21 21 -23 33 -4 27 -14 27 -30 0 -7 -11 -19 -18 -27 -16 -8 4 -12 -1
-11 -11 4 -22 34 -28 42 -8 8 20 24 21 24 1 0 -8 -7 -15 -15 -15 -9 0 -15 -9
-15 -25 0 -27 10 -31 42 -14 18 10 25 8 39 -10 23 -29 32 -26 27 8 -3 23 -1
27 14 23 12 -3 18 1 18 11 0 8 6 18 13 20 7 3 5 6 -5 6 -10 1 -18 -3 -18 -9 0
-5 -4 -10 -10 -10 -5 0 -10 7 -10 15 0 9 -7 18 -15 21 -11 4 -12 10 -6 18 16
19 -1 34 -25 22z"/>
<path d="M3766 4224 c-6 -16 3 -24 30 -24 16 0 21 5 17 18 -5 20 -40 24 -47 6z"/>
<path d="M3836 4233 c-11 -11 15 -42 35 -40 18 1 18 3 5 13 -9 6 -13 13 -10
16 2 3 -2 8 -10 11 -8 3 -17 3 -20 0z"/>
<path d="M4035 4230 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1956 4205 c-5 -19 -3 -31 6 -36 9 -6 9 -9 1 -9 -6 0 -19 10 -27 22
-9 12 -17 17 -17 10 -1 -6 -3 -27 -5 -44 -2 -18 -4 -43 -5 -55 -1 -13 -6 -23
-12 -23 -6 0 -5 -7 4 -15 17 -17 14 -79 -3 -69 -6 4 -8 -2 -6 -12 2 -11 10
-19 19 -19 9 0 23 -8 32 -17 15 -14 15 -17 1 -22 -23 -9 -27 -7 -21 8 7 17
-41 32 -56 17 -13 -13 10 -42 28 -35 7 3 22 1 33 -5 16 -8 22 -7 28 7 4 9 6
21 5 27 -1 5 6 15 15 22 16 12 14 13 -14 13 -30 0 -44 14 -23 22 15 5 18 43 4
41 -19 -4 -16 4 13 42 14 18 21 36 15 39 -5 3 -12 0 -14 -6 -3 -7 -6 -3 -6 9
-1 17 3 20 20 16 19 -5 20 -3 13 33 -10 59 -20 72 -28 39z"/>
<path d="M4240 4220 c0 -6 5 -20 10 -31 13 -24 -11 -75 -39 -84 -26 -8 -36
-35 -12 -35 14 0 18 -6 16 -25 -2 -14 1 -28 7 -31 13 -8 28 14 28 42 0 34 21
46 58 32 34 -13 53 -41 25 -36 -10 2 -19 -5 -21 -14 -4 -20 22 -25 33 -7 5 6
12 4 21 -8 14 -17 15 -16 8 6 -4 16 -3 21 5 17 6 -4 11 0 11 9 0 11 -5 15 -15
12 -8 -4 -17 -2 -20 3 -4 6 -1 10 4 10 6 0 9 14 7 33 -3 29 -7 33 -50 41 -25
5 -46 13 -46 18 0 4 5 8 11 8 6 0 8 7 5 16 -3 8 -2 12 3 9 5 -3 12 -1 16 5 3
5 -3 10 -14 10 -10 0 -26 3 -35 6 -9 3 -16 1 -16 -6z m20 -119 c0 -5 -7 -14
-15 -21 -12 -10 -15 -10 -15 2 0 8 3 18 7 21 9 10 23 9 23 -2z"/>
<path d="M4170 4207 c0 -22 24 -22 41 1 12 15 12 16 -2 5 -12 -10 -18 -10 -27
-1 -9 9 -12 8 -12 -5z"/>
<path d="M3530 4196 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3635 4190 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M3597 4182 c-2 -4 4 -15 14 -24 14 -13 20 -14 28 -4 8 10 5 16 -14
24 -13 6 -26 8 -28 4z"/>
<path d="M3102 4175 c4 -4 1 -13 -8 -19 -14 -10 -13 -13 3 -28 27 -25 73 -34
73 -15 0 8 3 23 6 31 4 12 2 15 -9 10 -8 -3 -28 2 -44 12 -15 10 -25 14 -21 9z"/>
<path d="M2940 4151 c0 -17 18 -21 24 -6 3 9 0 15 -9 15 -8 0 -15 -4 -15 -9z"/>
<path d="M1726 4137 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M3977 4138 c4 -7 8 -25 9 -40 1 -21 -3 -28 -17 -28 -11 0 -19 -5 -19
-11 0 -5 3 -8 8 -6 6 4 7 -24 3 -71 0 -7 -5 -10 -11 -7 -5 3 -10 2 -10 -4 0
-6 -6 -8 -14 -5 -22 8 -7 -47 20 -75 23 -23 33 -48 15 -37 -5 4 -11 1 -13 -6
-6 -18 33 -37 50 -25 17 13 15 27 -5 27 -9 0 -13 3 -10 7 4 3 2 12 -4 19 -16
20 -14 73 4 92 15 15 15 17 -1 35 -12 15 -14 21 -4 24 6 3 12 10 12 17 0 7 7
21 15 32 14 18 15 18 9 0 -6 -15 -3 -17 14 -11 15 4 22 2 22 -7 0 -10 2 -10 9
1 11 18 3 41 -12 35 -7 -2 -18 9 -25 26 -9 19 -21 30 -33 30 -14 0 -18 -4 -12
-12z"/>
<path d="M2116 4106 c-4 -19 -12 -36 -18 -38 -6 -2 -3 -11 8 -23 17 -17 19
-17 22 -2 7 35 21 16 15 -20 -5 -36 -4 -37 12 -22 13 13 15 25 10 48 -4 17
-11 31 -16 31 -5 0 -9 11 -9 24 0 39 -17 40 -24 2z"/>
<path d="M2224 4125 c-4 -8 -4 -21 -1 -29 3 -8 -4 -18 -17 -24 -13 -5 -23 -17
-23 -26 0 -13 3 -13 16 -3 12 10 14 9 8 -6 -4 -10 -7 -21 -7 -23 0 -2 -7 -4
-14 -4 -12 0 -13 -6 -5 -29 13 -36 33 -32 36 7 4 58 10 77 27 80 16 4 16 5 -1
18 -17 13 -17 15 0 28 15 12 16 14 2 20 -9 3 -18 -1 -21 -9z"/>
<path d="M2690 4022 c-70 -89 -85 -114 -90 -152 -5 -41 -7 -45 -32 -45 -23 0
-38 -15 -97 -101 -69 -100 -70 -101 -59 -142 6 -23 14 -42 19 -42 5 0 9 -8 9
-17 0 -15 2 -16 9 -3 6 8 7 18 5 22 -3 4 4 8 16 8 17 0 19 4 14 25 -5 18 1 35
20 59 14 19 26 39 26 44 0 5 6 14 13 20 17 14 42 -1 34 -21 -7 -18 38 -53 48
-37 7 12 45 14 45 3 0 -5 -18 -17 -40 -27 -45 -22 -44 -36 4 -36 40 0 59 -16
46 -40 -8 -14 -5 -19 14 -22 22 -4 23 -3 13 29 -6 18 -14 33 -18 33 -4 0 -7
14 -7 30 1 17 6 30 14 30 21 0 28 22 11 35 -10 7 -17 17 -17 24 0 17 -37 13
-44 -5 -5 -12 -10 -14 -20 -5 -7 6 -16 8 -20 4 -4 -4 -4 1 0 11 6 15 3 17 -15
11 -19 -6 -22 -4 -19 12 2 10 12 17 21 17 13 -2 15 1 7 11 -8 10 -4 20 14 41
l25 29 1 -32 c0 -23 5 -33 15 -33 8 0 15 6 15 13 1 8 5 6 13 -4 10 -13 15 -14
24 -4 9 10 8 14 -8 18 -10 3 -19 10 -19 15 0 7 11 5 28 -4 15 -7 34 -14 42
-13 13 0 13 1 0 6 -8 4 -30 17 -47 31 -18 13 -40 22 -48 18 -12 -4 -13 -2 -2
14 6 11 13 16 15 10 7 -20 19 -10 16 13 -3 12 2 30 10 40 12 16 18 17 38 6 13
-7 26 -12 31 -11 4 1 7 -5 7 -13 0 -8 -5 -15 -11 -15 -8 0 -7 -5 2 -16 11 -13
9 -14 -14 -9 -23 6 -27 4 -27 -14 0 -27 22 -36 50 -21 25 13 55 -4 46 -26 -9
-22 12 -26 24 -4 6 11 7 20 3 20 -4 1 9 10 30 21 42 22 56 44 38 62 -16 16
-14 32 3 18 10 -8 16 -9 21 -1 3 5 0 10 -7 10 -10 0 -10 2 1 9 9 6 10 11 2 15
-6 4 -11 15 -10 24 0 11 3 12 6 5 2 -7 11 -13 20 -13 13 0 13 2 -1 15 -19 20
-18 20 -55 -15 -31 -31 -42 -75 -21 -96 6 -6 2 -14 -12 -23 -20 -13 -21 -12
-15 28 3 23 1 39 -3 36 -13 -8 -40 6 -40 20 0 7 3 15 8 18 4 2 2 2 -5 1 -7 -2
-15 1 -18 7 -4 5 -10 7 -15 4 -14 -9 -20 26 -9 48 7 12 29 43 50 68 32 38 36
48 23 53 -43 17 -59 5 -144 -102z"/>
<path d="M2977 4124 c-4 -4 2 -13 13 -21 11 -8 17 -20 13 -29 -4 -10 2 -17 16
-21 11 -3 21 -9 21 -13 0 -21 -14 -40 -29 -40 -10 0 -12 -3 -5 -8 6 -4 13 -15
17 -25 11 -28 30 -8 23 24 -5 25 -2 29 17 30 22 1 28 1 48 -3 6 -2 13 2 15 7
6 14 -64 90 -74 80 -4 -4 3 -14 16 -22 27 -19 28 -29 3 -29 -11 0 -26 14 -37
34 -10 18 -23 31 -30 28 -7 -3 -14 0 -16 5 -2 6 -7 7 -11 3z"/>
<path d="M3230 4115 c-11 -13 -10 -15 4 -9 9 3 16 2 16 -4 0 -6 -6 -13 -12
-15 -8 -3 -7 -6 4 -6 24 -1 33 18 16 35 -13 13 -16 13 -28 -1z"/>
<path d="M1760 4105 c0 -9 -5 -13 -11 -9 -7 4 -10 1 -7 -6 2 -8 9 -15 15 -17
13 -5 24 35 12 43 -5 3 -9 -2 -9 -11z"/>
<path d="M2028 4114 c-4 -3 -4 -12 -2 -19 3 -7 -9 -24 -26 -39 -18 -14 -30
-31 -28 -37 4 -13 52 28 68 60 11 22 2 49 -12 35z"/>
<path d="M3337 4113 c-3 -5 1 -17 9 -28 14 -18 14 -18 14 2 0 23 -15 39 -23
26z"/>
<path d="M3910 4100 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4106 4094 c-3 -9 -2 -23 3 -31 4 -9 6 -24 3 -35 -4 -18 -5 -18 -19
2 -11 16 -18 18 -28 10 -8 -7 -18 -9 -24 -6 -5 4 -15 -10 -21 -29 -6 -19 -15
-35 -20 -35 -5 0 -2 -14 7 -31 11 -22 21 -29 34 -26 11 3 19 1 19 -4 0 -5 10
-8 23 -8 16 1 18 2 5 6 -10 2 -18 11 -18 19 0 8 5 12 10 9 6 -3 10 -1 10 6 0
7 -13 10 -35 7 -31 -3 -35 -1 -35 18 0 25 62 62 69 42 2 -7 -1 -14 -7 -16 -7
-2 -12 -9 -12 -15 0 -8 4 -7 12 1 17 17 28 15 22 -5 -2 -10 1 -8 7 4 7 13 20
25 30 28 30 10 18 70 -13 70 -7 0 -12 8 -10 18 4 21 -4 22 -12 1z"/>
<path d="M3280 4086 c0 -8 9 -18 20 -21 11 -3 20 -11 20 -16 0 -5 4 -9 9 -9 6
0 6 12 0 30 -11 31 -49 43 -49 16z"/>
<path d="M1990 4079 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2276 4074 c-12 -31 -7 -56 10 -50 9 3 17 6 18 6 1 0 1 14 -2 30 -5
33 -16 39 -26 14z"/>
<path d="M3855 4070 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M3915 4054 c-4 -16 -9 -35 -11 -43 -4 -11 0 -11 14 2 10 9 18 26 17
37 -2 32 -14 34 -20 4z"/>
<path d="M1870 4028 c0 -17 2 -18 15 -8 8 7 15 14 15 15 0 2 -7 5 -15 9 -10 4
-15 -1 -15 -16z"/>
<path d="M2985 4040 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M2788 4010 c-19 -33 -11 -40 13 -12 26 31 31 42 17 42 -7 0 -21 -14
-30 -30z"/>
<path d="M2870 4010 c6 -11 15 -20 20 -20 17 0 11 15 -11 28 -19 12 -19 11 -9
-8z"/>
<path d="M4266 4011 c-3 -5 1 -11 9 -15 8 -3 15 -10 15 -16 0 -14 -27 -13 -33
3 -4 8 -6 6 -6 -4 -1 -9 9 -24 21 -35 12 -10 18 -14 15 -7 -4 6 -3 14 3 18 5
3 10 -1 10 -11 0 -16 10 -16 55 2 17 6 18 5 8 -7 -8 -10 -9 -20 -2 -29 8 -13
13 -11 30 10 12 14 16 26 10 28 -6 2 -11 13 -11 25 -1 21 -1 21 -14 4 -9 -12
-16 -14 -21 -7 -4 6 -16 8 -28 4 -18 -5 -20 -4 -9 7 14 16 16 33 2 24 -5 -3
-10 -1 -10 4 0 13 -37 15 -44 2z"/>
<path d="M1996 3995 c-3 -8 -1 -15 4 -15 13 0 22 18 11 24 -5 3 -11 -1 -15 -9z"/>
<path d="M2090 3995 c-7 -9 -20 -13 -28 -10 -10 4 -12 0 -8 -11 5 -14 4 -15
-8 -5 -10 9 -16 9 -21 1 -10 -17 1 -24 44 -26 33 -1 38 1 34 16 -3 10 0 21 6
24 13 9 15 26 2 26 -5 0 -14 -7 -21 -15z"/>
<path d="M2262 3998 c-7 -7 -12 -19 -11 -27 0 -11 2 -12 6 -3 7 17 21 15 26
-5 4 -15 5 -15 6 2 1 17 3 18 11 5 9 -13 10 -13 10 1 0 15 -18 39 -30 39 -3 0
-11 -5 -18 -12z"/>
<path d="M3277 4003 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M3845 3990 c-4 -6 -1 -10 4 -10 6 0 11 -7 11 -15 0 -8 5 -15 10 -15
6 0 10 9 10 19 0 21 -26 36 -35 21z"/>
<path d="M2850 3986 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3170 3977 c0 -7 11 -12 24 -12 28 0 26 10 -4 18 -11 3 -20 0 -20 -6z"/>
<path d="M3337 3953 c-14 -36 -15 -36 -26 -15 -16 30 -29 28 -23 -3 2 -14 1
-25 -2 -25 -4 0 -11 11 -16 25 -5 14 -16 25 -25 25 -21 0 -19 -13 5 -28 11 -7
20 -17 20 -23 0 -5 -5 -7 -12 -3 -7 4 -8 3 -4 -4 4 -6 12 -9 19 -6 7 2 21 -2
31 -9 18 -13 21 -10 43 30 12 24 23 51 23 59 0 26 -18 14 -33 -23z"/>
<path d="M3380 3959 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3052 3936 c-8 -19 -8 -27 3 -36 11 -9 12 -17 5 -30 -13 -24 -4 -29
40 -25 19 2 48 4 63 4 26 1 27 3 13 16 -9 9 -22 25 -30 37 -20 29 -49 17 -40
-17 4 -15 2 -25 -5 -25 -6 0 -11 14 -11 30 0 17 5 30 10 30 6 0 5 8 -2 20 -17
27 -32 25 -46 -4z"/>
<path d="M4123 3948 c3 -10 2 -20 -3 -23 -15 -9 -1 -31 26 -44 30 -13 40 -9
47 19 3 12 0 20 -8 20 -7 0 -25 10 -41 22 -22 18 -27 19 -21 6z"/>
<path d="M4440 3952 c0 -7 -7 -12 -16 -12 -9 0 -14 -7 -12 -17 1 -10 -1 -23
-5 -30 -4 -6 -3 -14 2 -17 11 -7 3 -44 -13 -65 -9 -12 -7 -13 7 -8 10 4 23 7
28 7 5 0 9 9 9 19 0 10 7 21 15 25 9 3 12 12 8 22 -6 16 -7 16 -19 0 -7 -10
-15 -13 -18 -7 -4 5 -2 12 4 16 6 4 8 10 5 15 -3 5 -1 19 5 31 7 11 9 23 6 27
-3 3 -6 0 -6 -6z"/>
<path d="M2220 3940 c0 -5 -10 -10 -22 -10 -21 -1 -21 -2 -5 -11 9 -5 17 -18
17 -27 0 -13 4 -11 16 7 15 20 18 21 26 8 5 -9 15 -14 22 -11 12 5 11 9 -2 23
-9 10 -20 16 -24 14 -5 -2 -8 1 -8 6 0 6 -4 11 -10 11 -5 0 -10 -4 -10 -10z"/>
<path d="M3160 3942 c0 -15 11 -21 27 -15 12 5 12 7 1 14 -17 11 -28 11 -28 1z"/>
<path d="M2980 3931 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M3873 3929 c-28 -10 -30 -19 -3 -14 13 2 20 -2 20 -11 0 -8 5 -14 10
-14 11 0 12 8 2 31 -5 12 -13 14 -29 8z"/>
<path d="M3976 3915 c4 -8 10 -15 15 -15 5 0 9 7 9 15 0 8 -7 15 -15 15 -9 0
-12 -6 -9 -15z"/>
<path d="M1966 3883 c-30 -28 -44 -34 -68 -31 -19 3 -32 0 -35 -9 -3 -7 -9
-10 -14 -7 -5 3 -5 -6 0 -20 7 -18 6 -28 -4 -36 -7 -6 -12 -21 -10 -32 10 -50
28 -88 41 -88 8 0 14 -4 14 -10 0 -5 -5 -10 -11 -10 -5 0 -7 -4 -4 -10 11 -18
32 -10 44 17 11 23 10 29 -7 42 -11 9 -23 26 -27 39 -3 12 -13 22 -21 22 -8 0
-12 5 -9 10 4 6 10 8 15 5 12 -8 18 30 7 50 -9 16 -6 17 22 12 25 -5 31 -3 31
10 0 14 3 14 21 -3 20 -18 21 -18 32 1 7 11 12 25 12 31 0 6 6 18 13 27 26 31
-4 24 -42 -10z m-92 -168 c-4 -8 -11 -12 -16 -9 -6 4 -5 10 3 15 19 12 19 11
13 -6z"/>
<path d="M2047 3893 c-23 -12 -25 -17 -17 -34 6 -10 19 -19 29 -19 22 0 43
-22 38 -41 -2 -8 -2 -11 0 -6 8 14 22 -4 16 -20 -5 -14 -1 -15 25 -10 28 6 46
-6 23 -15 -4 -1 -5 -12 0 -23 7 -19 8 -19 8 3 1 16 7 22 25 22 30 0 39 20 17
38 -14 11 -12 12 14 11 54 -2 59 -1 45 16 -10 12 -17 12 -53 -2 -23 -9 -45
-20 -48 -25 -8 -12 -55 7 -62 26 -6 16 17 41 30 33 4 -3 14 1 21 9 7 8 8 14 2
14 -5 0 -19 9 -30 20 -23 23 -44 24 -83 3z"/>
<path d="M2170 3899 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2707 3896 c-3 -8 -3 -17 0 -20 9 -9 34 15 27 25 -8 13 -21 11 -27
-5z"/>
<path d="M2945 3894 c-19 -15 -19 -16 -2 -28 16 -13 16 -14 -9 -20 l-26 -7 21
-18 c20 -17 20 -58 1 -46 -11 7 -40 -5 -40 -16 0 -5 6 -9 14 -9 17 0 29 -27
15 -36 -6 -3 -8 -17 -4 -31 5 -21 3 -24 -15 -18 -26 8 -26 -11 0 -25 25 -13
60 -4 60 15 0 8 -9 17 -20 20 -11 3 -20 10 -20 15 0 5 7 7 15 4 18 -7 19 9 4
37 -9 17 -6 25 17 45 20 19 24 28 15 37 -15 15 -14 27 3 21 18 -7 31 43 15 62
-14 17 -19 17 -44 -2z"/>
<path d="M4006 3892 c-3 -3 3 -12 14 -19 16 -11 18 -18 10 -33 -13 -24 -13
-40 0 -40 6 0 10 7 10 15 0 20 17 19 29 -3 10 -19 77 -23 75 -4 -2 28 -5 32
-21 30 -39 -3 -54 3 -62 26 -9 22 -44 40 -55 28z"/>
<path d="M4252 3888 c-15 -19 2 -48 19 -31 7 7 8 17 2 28 -8 13 -11 14 -21 3z"/>
<path d="M1905 3880 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3229 3890 c0 -3 -2 -15 -5 -27 -2 -12 0 -25 5 -28 14 -8 25 35 12
48 -6 6 -11 9 -12 7z"/>
<path d="M4080 3875 c0 -8 10 -20 23 -26 19 -11 20 -10 10 3 -7 8 -10 17 -7
20 2 3 -2 8 -11 11 -9 4 -15 1 -15 -8z"/>
<path d="M4346 3882 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
<path d="M2240 3870 c0 -6 11 -8 26 -4 20 5 25 4 19 -6 -5 -9 -2 -10 14 -5 19
6 20 4 11 -20 -9 -23 -8 -26 6 -21 8 3 18 6 20 6 2 0 4 11 4 24 0 19 -6 24
-37 30 -53 8 -63 8 -63 -4z"/>
<path d="M4297 3859 c-3 -11 0 -19 7 -19 7 0 21 -9 31 -20 10 -11 23 -20 29
-20 7 0 7 3 1 8 -6 4 -16 19 -24 34 -18 34 -38 42 -44 17z"/>
<path d="M2161 3834 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3266 3834 c-8 -22 1 -31 16 -16 9 9 9 15 1 23 -7 7 -12 5 -17 -7z"/>
<path d="M2000 3825 c-6 -8 -9 -19 -5 -25 10 -16 -15 -12 -32 6 -20 19 -35 6
-35 -30 0 -14 -5 -26 -11 -26 -7 0 -7 -4 1 -12 15 -15 16 -42 0 -32 -7 5 -8 3
-4 -5 5 -7 14 -10 22 -7 8 3 14 1 14 -5 0 -6 5 -7 10 -4 6 3 10 15 10 26 0 10
6 19 13 20 6 0 2 5 -10 11 -30 14 -27 46 4 40 13 -2 23 -9 23 -14 0 -5 -5 -6
-12 -2 -7 4 -8 3 -4 -4 4 -7 12 -12 17 -12 11 0 46 31 42 37 -2 2 -3 6 -2 11
0 4 5 -4 11 -18 9 -21 12 -22 15 -7 6 22 -22 67 -41 67 -7 0 -19 -7 -26 -15z"/>
<path d="M3184 3818 c-4 -7 -4 -10 1 -6 4 4 16 3 26 -2 10 -6 20 -6 23 -1 3 5
-1 12 -10 15 -23 9 -32 7 -40 -6z"/>
<path d="M3060 3799 c-7 -11 -9 -23 -6 -27 3 -3 6 0 6 7 0 7 5 9 10 6 6 -3 10
-17 10 -31 0 -19 5 -24 24 -24 14 0 28 -5 31 -11 8 -11 45 17 45 34 0 6 -3 7
-7 4 -3 -4 -12 -2 -19 4 -8 7 -14 7 -19 -1 -12 -20 -26 -10 -15 11 9 17 8 23
-7 35 -25 19 -41 17 -53 -7z m50 -3 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0
16 -2 16 -4z"/>
<path d="M4457 3788 c3 -18 10 -25 19 -21 8 3 14 0 14 -6 0 -6 -6 -11 -14 -11
-17 0 -11 -34 9 -50 9 -8 14 -6 19 5 3 8 1 15 -5 15 -7 0 -8 12 -4 30 5 21 3
30 -6 30 -6 0 -17 8 -24 17 -12 16 -13 14 -8 -9z"/>
<path d="M2320 3789 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2356 3791 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M2860 3790 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M3910 3785 c7 -8 17 -15 22 -15 6 0 5 7 -2 15 -7 8 -17 15 -22 15 -6
0 -5 -7 2 -15z"/>
<path d="M4048 3782 c-10 -2 -18 -6 -18 -8 0 -10 29 -34 40 -34 8 0 10 -9 6
-24 -3 -14 -1 -28 4 -31 6 -4 10 -14 9 -23 -3 -36 1 -41 29 -37 15 3 41 7 57
9 17 2 31 5 33 5 1 1 -10 15 -25 32 -21 23 -33 29 -53 24 -18 -4 -21 -3 -11 4
12 8 12 12 2 25 -8 9 -20 16 -27 16 -8 0 -15 5 -15 10 -1 6 -2 14 -3 19 -2 14
-9 18 -28 13z m82 -123 c0 -11 -4 -18 -10 -14 -5 3 -7 12 -3 20 7 21 13 19 13
-6z"/>
<path d="M4380 3780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3220 3770 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4182 3760 c14 -22 24 -25 32 -11 3 4 -6 13 -19 19 -23 11 -24 10
-13 -8z"/>
<path d="M4280 3771 c0 -6 5 -13 10 -16 6 -3 10 -17 10 -31 0 -14 -4 -22 -10
-19 -20 13 -9 -7 17 -29 l28 -24 12 29 c7 16 18 28 25 27 9 -2 12 6 10 22 -6
40 -18 43 -34 10 l-15 -31 -17 25 c-9 14 -16 30 -16 36 0 5 -4 10 -10 10 -5 0
-10 -4 -10 -9z"/>
<path d="M2807 3753 c-22 -11 -31 -12 -43 -3 -20 17 -19 -8 1 -37 14 -20 14
-22 -5 -28 -21 -7 -28 -25 -9 -25 5 0 7 -6 3 -12 -4 -7 5 -2 19 11 20 18 24
28 17 42 -6 11 -6 20 -1 23 5 4 12 0 14 -6 3 -8 6 -6 6 5 1 10 5 16 10 13 14
-9 41 4 41 20 0 17 -16 16 -53 -3z"/>
<path d="M3930 3741 c-30 -28 -40 -73 -20 -86 5 -3 6 -13 3 -21 -5 -14 -3 -15
15 -5 28 15 32 33 14 54 -17 19 -3 24 17 5 11 -11 13 -7 8 24 -4 25 -2 36 6
34 7 -1 9 -1 4 1 -4 3 -5 9 -1 14 12 20 -18 7 -46 -20z"/>
<path d="M4394 3745 c-4 -9 -2 -21 4 -27 8 -8 8 -17 1 -30 -8 -15 -7 -23 8
-33 13 -10 15 -17 7 -25 -8 -8 -14 -9 -18 -1 -4 6 -17 11 -29 10 -21 -1 -21
-1 2 -8 14 -4 26 -11 28 -18 5 -15 -29 -26 -38 -12 -4 6 -12 8 -19 4 -6 -4
-22 2 -34 14 -23 22 -23 22 -29 2 -3 -12 -1 -24 4 -27 5 -3 6 -11 2 -17 -4 -7
0 -6 10 2 13 10 20 11 31 1 17 -13 12 -36 -9 -44 -8 -3 -25 4 -38 15 -27 21
-47 25 -47 9 0 -5 6 -10 13 -10 14 0 47 -31 47 -43 0 -3 -6 -12 -13 -19 -18
-18 -7 -61 13 -48 11 7 12 12 4 20 -13 13 4 60 22 60 5 0 19 6 30 14 16 13 17
18 7 35 -12 19 -11 20 18 14 25 -5 31 -2 36 16 3 11 10 21 17 21 6 0 24 7 39
15 15 8 25 10 22 6 -3 -5 2 -24 10 -42 l14 -34 1 47 c0 49 -12 62 -43 46 -10
-5 -25 -7 -33 -4 -21 8 -17 37 6 43 25 7 25 15 3 35 -32 27 -43 30 -49 13z"/>
<path d="M2030 3735 c-7 -8 -8 -15 -2 -15 5 0 15 7 22 15 7 8 8 15 2 15 -5 0
-15 -7 -22 -15z"/>
<path d="M3010 3741 c0 -17 18 -21 24 -6 3 9 0 15 -9 15 -8 0 -15 -4 -15 -9z"/>
<path d="M2260 3727 c0 -30 68 -56 77 -29 3 7 -5 12 -20 12 -14 0 -33 7 -41
16 -13 13 -16 13 -16 1z"/>
<path d="M4250 3731 c0 -14 18 -23 31 -15 8 4 7 9 -2 15 -18 11 -29 11 -29 0z"/>
<path d="M2110 3715 c0 -18 -12 -19 -27 -4 -9 9 -10 7 -6 -10 3 -11 11 -21 17
-21 6 0 20 -9 29 -21 18 -20 18 -21 -2 -14 -25 8 -28 -10 -3 -19 9 -3 22 -8
28 -11 6 -3 11 0 11 7 0 7 2 18 3 23 5 21 -1 35 -14 35 -16 0 -29 27 -20 41 3
5 0 9 -5 9 -6 0 -11 -7 -11 -15z"/>
<path d="M2222 3705 c-15 -32 -15 -32 3 -32 8 0 12 4 9 8 -3 5 0 18 6 29 6 11
7 20 2 20 -5 0 -14 -11 -20 -25z"/>
<path d="M4180 3720 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z"/>
<path d="M2989 3705 c-1 -11 -2 -27 -1 -35 1 -18 -36 -50 -58 -50 -19 0 -34
-26 -24 -41 11 -18 -16 -8 -37 14 -12 12 -19 16 -15 10 4 -7 3 -13 -2 -13 -11
0 -34 25 -28 31 2 2 -16 4 -40 4 -24 0 -44 -4 -44 -8 0 -5 -7 -3 -17 4 -14 12
-16 12 -11 -1 2 -8 11 -15 19 -16 8 0 22 -4 32 -9 10 -4 23 -2 32 5 9 7 18 10
22 6 4 -3 -3 -13 -15 -22 -24 -16 -21 -37 3 -27 8 3 15 0 15 -6 0 -6 8 -11 18
-11 10 0 23 -5 30 -12 9 -9 12 -8 12 5 0 9 4 17 10 17 5 0 7 -6 4 -14 -3 -8
-1 -17 4 -20 6 -3 13 5 16 18 6 26 38 40 55 23 7 -7 7 -14 0 -24 -9 -10 -7
-13 10 -13 11 0 21 -4 21 -10 0 -5 -5 -10 -12 -10 -6 0 -9 -2 -6 -5 3 -3 13
-3 22 1 20 8 21 34 1 34 -8 0 -15 4 -15 8 0 5 14 7 30 4 18 -3 30 0 30 7 0 6
-3 11 -7 12 -49 4 -70 11 -80 26 -11 14 -11 20 2 32 12 12 18 13 27 3 14 -14
32 -16 24 -3 -8 13 31 20 43 8 18 -18 24 1 7 25 -21 29 -20 38 2 38 14 0 14 2
3 9 -8 5 -24 6 -35 1 -14 -5 -25 -2 -33 9 -11 14 -13 14 -14 -4z"/>
<path d="M4010 3710 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2828 3703 c-22 -5 -23 -17 -3 -33 19 -16 47 -5 43 17 -3 18 -14 23
-40 16z"/>
<path d="M2357 3689 c13 -13 -3 -59 -20 -59 -6 0 -7 -3 -4 -7 4 -3 -2 -13 -14
-21 -26 -18 -35 -9 -21 23 9 20 8 27 -5 40 -20 20 -34 8 -39 -34 -2 -21 0 -29
9 -25 7 2 19 -6 26 -20 20 -37 71 -43 71 -8 0 8 7 24 17 34 11 12 12 18 4 18
-9 0 -11 9 -6 29 5 22 2 30 -12 35 -14 6 -15 4 -6 -5z"/>
<path d="M3983 3683 c-7 -2 -13 -11 -13 -18 0 -7 6 -16 13 -18 7 -3 6 -6 -5
-6 -9 -1 -20 -5 -23 -11 -3 -5 0 -7 8 -4 10 3 14 -4 15 -26 0 -17 -2 -28 -6
-26 -4 2 -18 -2 -32 -10 -25 -14 -25 -14 -3 -20 22 -6 41 -34 23 -34 -6 0 -10
-7 -10 -16 0 -9 5 -12 13 -8 9 6 8 3 -1 -8 -8 -9 -20 -14 -28 -11 -17 6 -18
-9 -2 -25 26 -26 74 -4 59 27 -5 9 -13 30 -19 48 -9 29 -7 34 15 48 18 12 22
22 17 37 -4 12 -2 18 4 14 11 -7 23 41 15 61 -5 13 -18 15 -40 6z"/>
<path d="M1951 3665 c-29 -15 -12 -30 25 -21 26 7 34 -11 9 -20 -8 -4 -22 -21
-31 -40 -9 -19 -21 -31 -26 -28 -6 4 -5 10 3 15 10 6 6 8 -12 7 -25 -2 -36
-26 -17 -39 5 -3 10 -19 11 -36 1 -17 6 -29 10 -26 5 3 17 -3 28 -13 22 -19
33 -15 23 10 -3 9 -11 13 -18 9 -6 -3 -7 -2 -3 3 5 5 16 9 24 9 9 0 18 5 20
11 2 6 -10 10 -30 10 -26 -1 -33 -5 -29 -16 4 -12 2 -12 -8 -3 -11 10 -9 17
10 36 20 21 28 23 54 16 17 -5 31 -9 32 -9 2 0 -6 56 -12 86 -8 40 -33 56 -63
39z"/>
<path d="M3114 3663 c-3 -10 -10 -26 -15 -35 -7 -15 -5 -18 12 -18 11 0 18 4
14 9 -3 6 4 15 15 21 11 6 20 17 20 25 0 21 -40 19 -46 -2z"/>
<path d="M2062 3661 c-8 -5 -12 -16 -10 -24 2 -11 7 -14 16 -8 8 5 12 16 10
24 -2 11 -7 14 -16 8z"/>
<path d="M4240 3645 c0 -14 4 -25 9 -25 12 0 23 29 15 41 -10 18 -24 9 -24
-16z"/>
<path d="M2184 3612 c-28 -27 -48 -31 -68 -16 -24 17 -39 8 -26 -17 8 -14 18
-18 44 -14 29 3 33 1 29 -14 -3 -11 0 -22 6 -25 7 -5 11 3 11 18 0 15 6 26 14
26 7 0 20 8 27 17 12 15 12 16 -8 11 -20 -5 -21 -4 -12 13 12 24 8 24 -17 1z"/>
<path d="M2506 3623 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M4224 3605 c10 -8 23 -12 29 -8 11 7 -12 23 -33 23 -12 -1 -11 -4 4
-15z"/>
<path d="M4090 3600 c0 -4 -3 -16 -6 -25 -4 -10 -2 -15 7 -13 9 2 13 -5 11
-21 -2 -18 2 -22 16 -18 11 2 26 1 34 -4 9 -5 23 -2 38 8 l23 16 -23 9 c-14 5
-28 4 -36 -3 -18 -15 -20 11 -2 29 9 9 9 12 -1 12 -7 0 -10 3 -8 8 3 4 -8 8
-24 9 -16 1 -29 -2 -29 -7z m40 -20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0
6 5 10 10 10 6 0 10 -4 10 -10z"/>
<path d="M2038 3593 c10 -4 10 -8 1 -19 -9 -11 -8 -18 5 -33 21 -23 58 -17 49
7 -8 23 -40 52 -55 51 -11 0 -11 -2 0 -6z"/>
<path d="M3064 3587 c-8 -21 2 -35 16 -21 5 5 6 15 0 24 -8 12 -10 12 -16 -3z"/>
<path d="M4422 3585 c-10 -12 -10 -15 0 -15 27 0 58 13 52 21 -8 14 -37 10
-52 -6z"/>
<path d="M3910 3579 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2240 3571 c0 -17 30 -25 43 -12 8 8 8 11 0 11 -6 0 -18 3 -27 6 -9
3 -16 1 -16 -5z"/>
<path d="M2512 3554 c-18 -13 -21 -19 -11 -30 6 -8 8 -14 4 -14 -4 0 -1 -8 6
-17 12 -15 12 -16 -3 -10 -12 4 -15 2 -11 -9 7 -20 32 -18 40 4 4 9 15 23 25
30 22 16 23 26 2 46 -19 20 -24 20 -52 0z"/>
<path d="M2200 3551 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z"/>
<path d="M2366 3541 c-17 -18 -16 -20 4 -26 26 -8 45 7 37 29 -8 21 -20 20
-41 -3z"/>
<path d="M2738 3531 c-5 -31 12 -53 32 -41 8 5 8 10 2 14 -6 3 -8 14 -5 25 3
10 -2 21 -10 24 -11 4 -16 -3 -19 -22z"/>
<path d="M4450 3550 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M4020 3544 c0 -9 40 -23 44 -15 3 5 -5 11 -19 13 -14 3 -25 4 -25 2z"/>
<path d="M4424 3542 c3 -6 2 -13 -3 -16 -5 -4 -12 -2 -16 4 -4 6 -11 7 -17 4
-6 -4 -6 -9 3 -15 11 -6 10 -12 -5 -28 -14 -15 -15 -24 -7 -38 6 -10 11 -12
11 -5 0 6 0 15 1 20 0 4 11 13 25 20 25 13 32 46 12 58 -6 4 -8 3 -4 -4z"/>
<path d="M2323 3525 c-8 -9 -9 -16 -3 -20 6 -3 13 1 16 10 9 21 2 26 -13 10z"/>
<path d="M2930 3530 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2135 3520 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2193 3519 c-10 -10 -9 -15 5 -25 9 -8 12 -14 5 -14 -6 0 -15 5 -18
10 -12 20 -35 10 -35 -15 0 -14 5 -25 11 -25 5 0 7 -4 4 -10 -3 -6 1 -7 9 -4
9 3 14 10 11 14 -3 5 4 6 15 3 11 -3 28 1 38 8 15 13 16 12 4 -3 -9 -12 -10
-18 -2 -23 6 -4 14 -4 18 0 7 7 8 12 11 40 0 8 9 15 19 15 14 0 14 2 3 9 -9 6
-22 4 -36 -5 -23 -15 -36 -8 -46 24 -3 10 -7 10 -16 1z"/>
<path d="M2037 3507 c4 -7 6 -22 4 -34 -3 -26 23 -32 33 -8 8 22 -2 42 -25 48
-13 3 -17 1 -12 -6z"/>
<path d="M4460 3504 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M2686 3494 c-3 -8 1 -22 9 -30 8 -9 15 -11 15 -5 0 5 -5 13 -11 17
-8 5 -8 9 1 14 8 5 8 10 2 14 -5 3 -13 -1 -16 -10z"/>
<path d="M2800 3496 c0 -8 -11 -17 -25 -20 -14 -4 -25 -11 -25 -17 0 -7 10 -9
25 -5 18 5 25 2 25 -9 0 -8 -5 -15 -12 -15 -6 0 -9 -2 -6 -5 10 -10 39 8 34
21 -3 8 -1 14 4 14 16 0 11 37 -5 44 -9 3 -15 0 -15 -8z"/>
<path d="M2908 3503 c-19 -5 -33 -37 -23 -53 3 -6 -1 -7 -9 -4 -9 3 -16 14
-16 23 0 14 -2 14 -10 1 -13 -20 -13 -67 1 -72 5 -2 7 -11 4 -21 -4 -9 -11
-15 -15 -12 -4 3 -13 -2 -19 -9 -9 -11 -9 -20 1 -38 7 -13 13 -24 14 -26 3 -8
66 33 74 48 8 15 6 19 -13 22 -20 3 -25 19 -18 60 3 16 41 -6 41 -24 0 -13 6
-18 17 -16 29 5 28 37 -1 48 -14 5 -26 14 -26 20 0 13 33 13 47 -1 6 -6 16 -8
22 -5 17 11 13 26 -8 26 -21 0 -45 23 -34 33 7 7 -2 7 -29 0z m-28 -167 c0 -9
-7 -12 -20 -9 -11 3 -20 9 -20 14 0 5 9 9 20 9 11 0 20 -6 20 -14z"/>
<path d="M4019 3495 c-1 -11 -1 -24 0 -29 3 -13 -30 -46 -46 -46 -20 0 -41
-22 -35 -38 2 -7 -2 -10 -12 -6 -9 3 -16 12 -16 20 0 8 -5 14 -12 14 -6 0 -9
-3 -5 -6 3 -3 2 -17 -3 -30 -8 -19 -5 -26 10 -34 13 -7 20 -6 23 3 3 7 6 5 6
-5 1 -22 21 -24 21 -3 0 17 36 39 51 30 5 -4 7 -15 4 -26 -5 -13 -2 -19 7 -17
7 2 12 -2 11 -9 -2 -7 4 -10 12 -6 10 3 13 12 9 26 -5 15 -3 19 10 14 8 -3 18
-2 21 3 5 9 -11 15 -60 22 -22 4 -32 35 -17 50 6 6 17 6 32 -2 18 -9 21 -9 15
0 -6 9 0 11 24 8 33 -5 41 8 19 30 -10 10 -10 15 1 21 7 5 11 13 7 19 -8 13
-46 4 -46 -11 0 -6 -7 -2 -15 9 -14 19 -14 19 -16 -1z"/>
<path d="M2409 3489 c-8 -5 -26 -6 -41 -3 -22 4 -28 2 -28 -12 0 -26 -29 -54
-48 -46 -15 5 -15 4 -3 -11 18 -22 28 -21 36 3 6 20 7 20 33 -1 l27 -21 6 24
c4 14 7 16 8 7 1 -9 7 -19 15 -22 11 -5 13 0 6 24 -7 24 -6 31 7 36 9 4 19 1
21 -5 2 -7 8 -12 13 -12 12 0 -12 38 -27 44 -6 2 -17 0 -25 -5z"/>
<path d="M2585 3491 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M2010 3481 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M4136 3449 c-15 -30 -14 -32 7 -50 l22 -18 -27 -1 c-16 0 -28 5 -28
10 0 6 -4 10 -10 10 -5 0 -10 -6 -10 -12 1 -16 68 -48 101 -48 14 0 30 -5 37
-12 9 -9 12 -8 12 6 0 9 4 15 9 12 5 -4 11 -1 13 5 7 21 47 -14 60 -51 7 -22
13 -29 16 -20 3 8 10 18 16 22 7 5 5 8 -6 8 -9 0 -20 5 -23 10 -10 16 22 11
40 -6 9 -9 19 -13 23 -9 4 4 1 10 -6 12 -7 3 -19 11 -26 19 -8 8 -20 14 -26
14 -19 0 -60 32 -60 47 0 8 -4 12 -10 8 -5 -3 -7 -12 -3 -20 3 -8 0 -15 -6
-15 -6 0 -11 5 -11 10 0 6 -7 10 -16 10 -8 0 -13 -4 -10 -9 3 -5 -1 -14 -9
-21 -11 -9 -14 -8 -14 7 0 10 -2 32 -6 50 -5 27 -3 32 17 39 l23 8 -25 7 c-42
12 -48 10 -64 -22z m39 -37 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9
12 -12z"/>
<path d="M4340 3466 c0 -8 -12 -24 -26 -35 -40 -31 -29 -55 29 -59 38 -3 47 0
47 13 0 9 -7 24 -16 32 -14 15 -15 15 -12 0 2 -9 -1 -15 -7 -14 -5 1 -8 19 -7
40 2 20 1 37 -2 37 -3 0 -6 -6 -6 -14z m-10 -66 c0 -5 -2 -10 -4 -10 -3 0 -8
5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M2300 3460 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2093 3446 c-23 -11 -32 -36 -13 -36 19 0 60 24 57 34 -5 14 -19 15
-44 2z"/>
<path d="M2548 3453 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1934 3417 c3 -21 9 -44 15 -51 6 -7 8 -16 4 -19 -3 -4 -1 -7 5 -7
16 0 15 30 0 31 -7 0 -2 6 12 12 32 15 51 33 43 41 -11 11 -23 6 -23 -9 0 -22
-11 -18 -37 13 l-23 27 4 -38z"/>
<path d="M2500 3439 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2600 3420 c-6 -11 -15 -18 -20 -15 -5 3 -11 1 -15 -5 -3 -5 -2 -10
4 -10 12 0 3 -29 -15 -47 -7 -7 -17 -13 -23 -13 -17 0 -13 -28 7 -39 15 -9 15
-12 2 -21 -13 -8 -13 -10 3 -10 10 0 17 8 17 20 0 11 -4 20 -10 20 -5 0 1 15
13 34 12 18 24 32 27 31 9 -5 36 19 43 38 4 10 14 17 24 15 22 -4 29 -28 9
-28 -21 0 -20 -27 1 -39 14 -7 15 -12 5 -24 -8 -9 -9 -18 -4 -21 6 -3 12 1 15
9 4 9 17 13 36 12 16 -1 27 -5 25 -9 -2 -3 2 -12 9 -20 8 -7 12 -19 9 -26 -2
-6 3 -15 12 -18 9 -3 16 -13 16 -21 0 -19 30 -13 43 8 13 21 -11 49 -42 49
-28 0 -36 18 -16 40 17 19 8 33 -17 26 -48 -15 -68 -16 -68 -1 0 8 3 14 8 13
11 -3 52 13 52 20 0 4 -4 13 -9 21 -7 10 -13 11 -31 1 -20 -11 -22 -10 -17 4
9 22 -15 31 -42 16 -14 -7 -21 -7 -21 0 0 17 -19 11 -30 -10z"/>
<path d="M2200 3420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2540 3419 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2756 3408 c3 -13 7 -23 8 -23 0 0 12 -6 25 -13 21 -13 23 -12 17 3
-3 9 -6 19 -6 21 0 2 -7 4 -15 4 -8 0 -15 7 -15 15 0 8 -5 15 -11 15 -6 0 -8
-9 -3 -22z"/>
<path d="M4425 3390 c-6 -34 -4 -46 8 -55 18 -13 26 -40 12 -43 -5 -2 -11 6
-13 18 -4 23 -32 28 -32 6 0 -8 -8 -21 -19 -28 -10 -7 -19 -17 -21 -23 -1 -5
-6 -9 -11 -7 -5 1 -9 -4 -9 -11 0 -7 16 -20 35 -29 29 -13 35 -13 35 -2 0 8
-4 14 -10 14 -11 0 -14 33 -3 43 3 4 12 -1 20 -11 10 -13 13 -14 11 -2 -3 16
3 17 40 5 23 -7 32 9 10 19 -10 3 -8 8 4 16 23 14 22 80 -1 87 -9 3 -23 15
-32 26 -16 20 -17 19 -24 -23z"/>
<path d="M2032 3400 c-14 -23 -15 -30 -3 -30 8 0 24 41 18 47 -2 1 -8 -7 -15
-17z"/>
<path d="M2185 3393 c13 -19 15 -28 6 -31 -9 -3 -7 -9 5 -23 9 -10 14 -27 11
-39 -3 -11 -1 -23 4 -26 5 -3 9 -13 9 -23 0 -16 -6 -18 -45 -13 -33 3 -45 1
-45 -8 0 -9 -6 -8 -19 4 -15 13 -20 14 -24 4 -3 -7 -10 1 -17 20 l-12 33 -24
-22 c-27 -25 -32 -44 -9 -35 19 7 20 -8 1 -23 -10 -9 -16 -8 -26 3 -6 9 -9 19
-6 25 3 5 -1 11 -9 15 -18 7 -18 5 -3 -31 6 -15 12 -32 13 -38 7 -34 20 -54
41 -64 16 -7 24 -7 24 0 0 5 -6 12 -13 15 -11 4 -21 64 -11 64 1 0 14 -4 28
-10 14 -5 28 -5 31 -1 3 5 23 8 44 7 22 -1 38 2 35 6 -10 16 7 29 23 17 9 -8
17 -9 21 -3 4 6 15 13 25 17 10 4 17 17 17 33 0 20 -3 24 -13 16 -9 -8 -17 -8
-27 0 -13 8 -12 9 2 5 17 -6 28 11 28 44 0 10 7 19 15 19 8 0 15 -7 15 -15 0
-8 4 -15 9 -15 10 0 8 28 -4 67 -6 18 -13 21 -46 19 -22 -2 -42 1 -45 5 -3 5
-10 9 -16 9 -6 0 -2 -12 7 -27z m84 -16 c8 -10 7 -14 -4 -14 -8 0 -15 6 -15
14 0 7 2 13 4 13 2 0 9 -6 15 -13z"/>
<path d="M2451 3411 c-8 -5 -9 -11 -3 -15 5 -3 15 1 22 9 14 16 3 20 -19 6z"/>
<path d="M2500 3410 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2065 3380 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M2350 3373 c0 -9 3 -13 6 -10 6 6 49 -40 49 -53 0 -3 3 -10 7 -15 3
-6 5 -27 4 -47 -1 -28 2 -34 11 -26 7 6 20 8 28 4 23 -8 18 10 -11 41 -18 19
-23 34 -19 51 3 14 2 21 -4 18 -12 -8 -41 21 -41 40 0 8 -7 14 -15 14 -8 0
-15 -7 -15 -17z"/>
<path d="M2465 3364 c-15 -16 -14 -21 14 -54 2 -3 1 7 -3 22 -4 20 -1 30 11
37 10 6 13 11 6 11 -6 0 -19 -7 -28 -16z"/>
<path d="M2089 3347 c6 -6 11 -18 11 -26 0 -12 11 -16 38 -17 20 -1 27 1 15 3
-23 4 -32 23 -12 23 27 0 0 23 -30 25 -24 2 -30 0 -22 -8z"/>
<path d="M2610 3320 c-1 -14 -2 -28 -1 -32 2 -12 -14 -9 -27 4 -9 9 -12 8 -12
-4 0 -19 17 -27 43 -20 18 5 19 13 7 52 l-8 25 -2 -25z"/>
<path d="M3962 3320 c-7 -11 -19 -20 -28 -20 -12 0 -15 -7 -12 -24 4 -17 1
-23 -9 -19 -7 3 -13 12 -13 20 0 8 -4 11 -10 8 -13 -8 -13 -75 0 -75 5 0 7 -7
3 -15 -3 -9 -8 -13 -11 -11 -3 3 -11 -2 -17 -11 -12 -16 -11 -21 12 -62 2 -2
11 2 20 10 16 13 17 12 5 -3 -8 -9 -10 -22 -6 -29 5 -8 9 -7 16 5 5 9 14 14
20 10 7 -4 6 -9 -3 -15 -22 -13 -3 -22 21 -9 15 8 19 17 14 28 -3 9 -7 26 -8
37 0 11 -10 22 -21 25 -18 5 -23 23 -16 62 2 12 31 -3 31 -16 0 -16 58 -59 69
-51 15 9 40 -6 34 -21 -4 -10 0 -12 11 -8 17 7 19 16 10 52 -5 15 -12 21 -22
17 -11 -4 -13 1 -10 18 4 22 2 24 -27 17 -35 -8 -79 5 -70 20 8 13 45 13 45 0
0 -14 13 -13 34 3 16 11 15 13 -9 15 -39 3 -47 11 -41 38 8 29 5 30 -12 4z
m-45 -167 c3 -7 -3 -9 -16 -6 -12 3 -21 10 -21 15 0 13 32 6 37 -9z"/>
<path d="M1987 3312 c-22 -25 -21 -39 3 -45 11 -3 20 0 20 6 0 7 3 22 6 35 8
27 -7 29 -29 4z"/>
<path d="M4088 3305 c-29 -25 -29 -45 0 -74 9 -10 10 -17 3 -24 -19 -19 -12
-26 13 -14 13 6 21 17 19 24 -3 8 2 13 13 13 16 0 17 2 4 11 -13 9 -13 10 0 6
18 -4 26 -37 13 -46 -15 -9 -25 -61 -13 -61 5 0 10 7 10 15 0 19 5 19 34 -1
15 -9 26 -11 30 -5 10 15 38 1 32 -15 -7 -17 6 -18 22 -2 14 14 16 32 4 24 -5
-3 -16 4 -25 14 -8 10 -12 20 -8 22 5 2 12 13 15 26 6 19 4 22 -18 22 -23 0
-26 -4 -26 -34 0 -29 -3 -33 -20 -29 -16 4 -19 11 -14 27 4 11 12 24 18 28 8
6 7 8 -1 8 -7 0 -13 4 -13 10 0 5 10 9 21 7 12 -1 24 4 26 11 3 6 -2 12 -11
12 -8 0 -20 8 -25 18 -9 15 -12 15 -21 2 -5 -8 -8 -21 -5 -29 4 -9 0 -11 -11
-7 -8 3 -28 6 -42 6 -20 0 -23 3 -12 9 8 6 18 7 22 5 4 -3 16 0 25 6 16 10 15
11 -4 7 -13 -3 -26 -1 -29 4 -3 5 2 9 10 9 9 0 16 5 16 10 0 17 -24 10 -52
-15z"/>
<path d="M2304 3299 c-7 -13 16 -21 29 -11 7 5 21 12 32 15 11 3 3 5 -18 4
-21 -1 -40 -4 -43 -8z"/>
<path d="M2716 3297 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4249 3291 c7 -12 9 -27 5 -33 -5 -7 -3 -8 6 -3 9 6 11 -2 7 -33 -4
-35 -1 -41 17 -46 11 -3 24 0 29 7 5 7 6 3 2 -11 -6 -21 -5 -22 34 -16 27 4
41 3 41 -4 0 -18 -20 -24 -35 -12 -8 7 -15 9 -15 5 0 -4 7 -13 17 -20 13 -10
20 -8 35 7 30 30 20 62 -21 66 -25 3 -31 0 -25 -10 4 -7 4 -10 -1 -6 -4 4 -9
24 -11 44 -4 44 -13 55 -47 57 -14 1 -25 6 -24 11 1 5 -4 11 -11 13 -11 4 -12
1 -3 -16z"/>
<path d="M2490 3263 c1 -27 1 -27 15 -9 14 19 12 36 -6 36 -5 0 -9 -12 -9 -27z"/>
<path d="M2360 3270 c-8 -5 -25 -10 -37 -10 -28 0 -30 -16 -4 -30 27 -14 41
-13 41 4 0 7 9 16 20 19 22 6 28 27 8 27 -7 0 -20 -5 -28 -10z"/>
<path d="M2650 3260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2625 3230 c7 -22 -10 -28 -19 -7 -2 6 -3 1 -2 -13 2 -14 -1 -30 -5
-36 -6 -7 -2 -20 9 -35 l19 -23 28 22 c15 12 23 22 16 22 -6 0 -11 5 -11 11 0
6 8 8 19 4 11 -3 22 -1 26 5 3 5 11 10 16 10 6 0 7 -5 3 -12 -5 -7 -3 -8 5 -4
7 5 10 10 7 13 -2 3 -1 16 5 30 6 16 6 22 -1 18 -6 -3 -10 -1 -10 4 0 6 -9 11
-19 11 -12 0 -21 -10 -26 -27 -6 -24 -8 -26 -16 -10 -6 9 -17 17 -25 17 -8 0
-12 5 -9 10 3 6 1 10 -5 10 -7 0 -9 -8 -5 -20z"/>
<path d="M2480 3214 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M4460 3220 c0 -7 -8 -9 -22 -5 -22 7 -22 6 -6 -10 15 -16 18 -16 32
-2 10 10 12 18 6 22 -5 3 -10 1 -10 -5z"/>
<path d="M2440 3200 c-10 -6 -11 -10 -2 -10 6 0 12 -4 12 -10 0 -5 -8 -7 -17
-4 -15 5 -15 3 2 -10 18 -15 20 -15 27 1 15 40 7 52 -22 33z"/>
<path d="M2503 3185 c-7 -8 -13 -19 -13 -25 0 -5 -4 -10 -8 -10 -4 0 -8 -9 -8
-20 0 -11 4 -20 8 -20 4 0 8 5 8 10 0 6 7 10 15 10 8 0 15 -9 15 -20 0 -11 5
-20 11 -20 5 0 9 -8 8 -17 -3 -33 1 -36 21 -18 24 22 26 40 4 31 -11 -4 -15
-2 -11 8 3 7 0 19 -6 26 -9 10 -9 14 1 17 6 3 12 12 12 20 0 14 -3 14 -20 -2
-24 -22 -36 -14 -21 13 18 34 8 45 -16 17z"/>
<path d="M2193 3179 c-25 -9 -38 -40 -21 -51 19 -12 28 -10 28 6 0 7 6 19 13
25 10 8 19 6 35 -10 12 -11 22 -26 22 -34 0 -20 -10 -19 -26 3 -12 15 -13 14
-14 -11 0 -16 4 -26 9 -22 5 3 11 -5 13 -17 2 -17 9 -23 26 -22 29 1 28 -10
-5 -34 l-28 -20 23 -20 c12 -11 22 -24 22 -30 0 -6 13 -10 30 -9 16 1 30 5 30
10 0 4 5 7 10 7 6 0 10 -9 10 -20 0 -11 5 -20 10 -20 6 0 10 11 10 25 0 15 -6
25 -14 25 -8 0 -17 6 -19 13 -3 7 -6 5 -6 -5 -1 -23 -46 -24 -55 -2 -9 24 -8
26 13 19 14 -4 20 0 24 20 6 34 -25 65 -56 57 -18 -4 -17 0 10 24 44 39 37 64
-26 85 -28 9 -56 13 -68 8z"/>
<path d="M2340 3180 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2050 3167 c0 -8 6 -20 14 -26 12 -10 17 -8 26 8 6 12 7 21 1 21 -5
0 -16 3 -25 6 -10 4 -16 1 -16 -9z"/>
<path d="M2370 3164 c-9 -10 -9 -14 -1 -14 6 0 11 5 11 11 0 8 5 8 15 -1 9 -7
15 -8 15 -2 0 17 -28 22 -40 6z"/>
<path d="M2750 3148 c-12 -22 -12 -22 6 -6 10 10 15 20 12 24 -4 3 -12 -5 -18
-18z"/>
<path d="M2124 3134 c3 -8 2 -13 -3 -10 -5 3 -11 -4 -15 -17 -10 -38 -7 -41
17 -15 27 29 31 58 9 58 -9 0 -12 -6 -8 -16z"/>
<path d="M3970 3132 c0 -6 5 -14 10 -17 6 -3 10 -15 10 -27 0 -13 5 -18 15
-15 11 5 13 -2 11 -28 -2 -25 2 -35 12 -35 11 0 11 -3 -2 -15 -8 -9 -13 -22
-10 -30 8 -19 9 -19 44 6 28 22 80 21 80 -1 0 -5 -7 -7 -15 -4 -21 8 -85 -24
-85 -42 0 -8 -4 -13 -9 -9 -5 3 -12 -4 -16 -15 -8 -26 -20 -25 -78 6 -26 14
-56 23 -65 20 -9 -3 -24 -1 -32 4 -12 8 -13 11 -1 19 8 5 11 16 6 30 -6 19 -3
21 33 21 34 0 38 -3 38 -22 -1 -16 4 -23 17 -22 9 1 17 -2 17 -7 0 -5 7 -9 15
-9 20 0 19 10 -5 40 -23 30 -25 44 -5 36 8 -3 15 -1 15 4 0 10 -13 14 -40 11
-10 -1 -7 6 9 19 l23 20 -23 -2 c-13 -2 -34 -13 -45 -25 -12 -12 -24 -19 -27
-17 -10 10 -67 -39 -82 -70 -8 -16 -13 -31 -10 -34 2 -3 7 3 11 12 8 21 24 21
24 -1 0 -10 5 -25 10 -33 8 -13 13 -12 41 4 28 17 32 17 47 3 14 -15 13 -16
-14 -14 -25 2 -29 -2 -32 -25 -3 -22 2 -29 23 -34 25 -6 25 -7 10 -30 -19 -30
-19 -31 6 -44 19 -10 20 -10 10 9 -16 29 -14 33 12 17 12 -8 33 -16 47 -18 19
-2 25 2 26 17 2 35 4 38 19 25 13 -11 14 -10 9 4 -3 9 -10 16 -15 16 -12 0 0
28 16 33 9 4 11 -1 8 -14 -3 -10 0 -19 6 -19 6 0 11 11 11 25 0 14 8 29 21 36
15 8 18 14 10 22 -17 17 -13 27 9 27 11 0 20 -5 20 -11 0 -8 5 -8 15 1 23 19
18 -3 -6 -29 l-21 -22 38 7 37 7 -7 -33 c-8 -41 -12 -46 -29 -32 -9 8 -17 8
-27 0 -13 -8 -13 -9 1 -5 9 3 16 0 16 -6 0 -15 -59 -16 -69 -2 -8 11 -10 4
-10 -45 0 -45 1 -50 10 -50 5 0 7 14 4 30 -4 31 13 41 35 19 8 -8 17 -8 30 0
14 7 24 7 33 -2 10 -10 12 -8 8 9 -4 13 1 25 13 33 22 13 25 31 5 31 -9 0 -12
7 -9 20 3 11 2 28 -2 37 -5 10 -8 21 -7 24 1 4 -3 21 -8 38 -6 22 -5 33 4 38
25 15 27 47 6 61 -12 7 -25 10 -29 7 -5 -2 -14 2 -21 10 -7 8 -28 17 -49 21
-20 3 -42 14 -50 23 -13 15 -13 15 -8 -1 8 -24 2 -23 -23 4 -11 12 -20 17 -20
10z m86 -66 c8 -8 14 -20 14 -26 0 -7 8 -9 19 -5 11 3 22 1 26 -5 3 -5 18 -10
32 -10 15 0 23 -3 19 -7 -13 -13 -100 -9 -115 5 -11 11 -12 15 -2 22 10 5 9 9
-3 13 -9 4 -16 11 -16 17 0 14 9 13 26 -4z m-71 -217 c10 -14 -24 -37 -47 -31
-10 2 -18 10 -18 18 0 8 6 11 15 8 8 -4 15 -1 15 5 0 14 27 14 35 0z"/>
<path d="M2717 3123 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M4143 3100 c-10 -15 -8 -19 11 -22 19 -4 22 -1 18 14 -7 26 -16 29
-29 8z"/>
<path d="M4200 3105 c-7 -9 -11 -18 -8 -20 3 -3 11 1 18 10 7 9 11 18 8 20 -3
3 -11 -1 -18 -10z"/>
<path d="M4436 3103 c-4 -9 -3 -20 2 -25 6 -6 10 1 10 16 0 30 -3 32 -12 9z"/>
<path d="M2386 3101 c-10 -15 5 -41 24 -41 13 0 16 -6 12 -27 -3 -16 -10 -32
-16 -36 -8 -6 -7 -10 2 -14 7 -2 19 -12 28 -21 13 -13 17 -13 25 -1 5 8 9 18
9 23 0 4 9 6 20 3 11 -3 23 -1 26 4 3 5 0 9 -7 9 -7 0 -9 8 -6 21 5 18 3 18
-20 -6 -14 -14 -29 -22 -32 -19 -3 4 0 9 7 11 7 3 17 16 22 29 5 13 17 24 26
24 13 0 14 3 5 14 -8 10 -24 12 -51 9 -31 -4 -41 -2 -46 11 -7 18 -20 21 -28
7z"/>
<path d="M2667 3099 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M3824 3095 c-4 -8 -1 -21 5 -29 6 -7 8 -16 4 -19 -3 -4 0 -7 9 -7 8
0 22 8 31 18 13 14 13 20 3 35 -16 21 -45 22 -52 2z"/>
<path d="M4234 3093 c-15 -5 -3 -65 14 -71 7 -2 12 5 12 17 0 11 4 22 9 25 5
3 9 -10 8 -29 -1 -41 2 -46 31 -38 25 6 28 21 7 37 -11 8 -12 15 -4 26 8 11
12 12 16 3 6 -16 35 -17 51 -1 9 9 15 9 23 1 8 -8 5 -13 -10 -18 -23 -7 -26
-21 -11 -45 7 -11 10 -11 10 -2 0 7 11 23 24 35 l25 23 -22 14 c-26 16 -114
23 -122 10 -6 -9 -36 -2 -45 10 -3 4 -10 5 -16 3z"/>
<path d="M2206 3075 c-3 -9 0 -15 9 -15 9 0 12 6 9 15 -4 8 -7 15 -9 15 -2 0
-5 -7 -9 -15z"/>
<path d="M2050 3066 c0 -7 7 -19 15 -26 9 -7 13 -21 10 -31 -5 -14 -1 -19 14
-19 24 0 29 28 6 36 -8 4 -15 17 -15 30 0 15 -6 24 -15 24 -8 0 -15 -6 -15
-14z"/>
<path d="M2690 3071 c0 -8 -7 -10 -21 -6 -19 6 -20 5 -8 -8 12 -13 16 -13 28
-1 8 7 12 17 8 20 -4 4 -7 2 -7 -5z"/>
<path d="M2176 3045 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M3987 3029 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M2637 3013 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M2564 2983 c-20 -30 -27 -63 -13 -63 5 0 9 9 9 21 0 24 26 53 40 44
6 -4 8 -11 5 -16 -4 -5 -1 -9 4 -9 15 0 14 19 -3 36 -19 19 -21 18 -42 -13z"/>
<path d="M4218 3003 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4240 2974 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M4295 2950 c-3 -5 1 -10 9 -10 8 0 18 5 21 10 3 6 -1 10 -9 10 -8 0
-18 -4 -21 -10z"/>
<path d="M4393 2943 c-7 -3 -13 -11 -13 -19 0 -8 -4 -14 -10 -14 -5 0 -10 -4
-10 -10 0 -15 24 -2 38 21 15 23 13 29 -5 22z"/>
<path d="M2027 2916 c4 -17 0 -25 -14 -29 -15 -4 -16 -5 -1 -6 19 -1 42 34 33
49 -12 19 -22 10 -18 -14z"/>
<path d="M2069 2920 c-3 -37 -4 -41 -18 -49 -7 -5 -11 -14 -7 -19 4 -7 -4 -10
-24 -7 -16 2 -30 -1 -30 -6 0 -5 7 -9 15 -9 8 0 15 -5 15 -12 0 -6 2 -9 6 -6
3 3 20 2 39 -2 29 -7 35 -5 41 12 3 10 0 25 -6 33 -11 13 -7 15 20 15 l33 0
-37 35 c-44 41 -46 41 -47 15z"/>
<path d="M2413 2933 c-7 -3 -13 -9 -13 -15 0 -6 7 -8 14 -5 10 4 13 1 10 -8
-3 -7 3 -16 15 -20 22 -7 29 -25 10 -25 -5 0 -8 -4 -4 -10 3 -5 11 -8 18 -6 7
1 9 1 4 -1 -4 -3 -5 -9 -2 -13 3 -5 1 -11 -5 -15 -5 -3 -10 -2 -10 4 0 6 -8
15 -18 20 -16 9 -18 6 -15 -26 2 -28 -1 -34 -13 -29 -9 3 -17 6 -20 6 -2 0 -1
7 2 15 4 10 0 15 -15 15 -31 0 -25 -27 9 -38 23 -8 36 -7 57 3 34 18 47 19 37
4 -5 -9 1 -10 22 -5 16 4 33 8 39 9 17 3 25 21 12 29 -9 6 -8 10 2 17 28 18
21 37 -12 35 -18 -1 -36 1 -42 4 -5 4 -16 7 -23 7 -7 0 -20 12 -30 26 -9 14
-23 24 -29 22z m112 -92 c-3 -6 -14 -11 -23 -11 -15 1 -15 2 2 15 21 16 32 13
21 -4z"/>
<path d="M2347 2913 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M4274 2909 c-3 -6 -2 -14 3 -18 24 -17 -1 -70 -34 -71 -7 0 -13 -7
-13 -15 0 -13 26 -21 54 -16 4 1 13 -3 20 -8 14 -11 56 -3 56 10 0 5 -5 9 -11
9 -8 0 -7 5 1 15 11 14 10 14 -14 5 -36 -13 -36 -14 -34 15 2 26 23 34 31 13
3 -7 6 3 6 23 1 25 -4 39 -15 43 -24 9 -42 7 -50 -5z"/>
<path d="M2220 2899 l-25 -7 26 -1 c14 -1 30 -6 37 -13 7 -7 12 -9 12 -5 0 4
8 3 19 -2 23 -13 34 -78 14 -85 -8 -3 -13 2 -13 15 0 11 -4 17 -10 14 -6 -4
-10 -16 -8 -28 2 -15 -4 -25 -20 -32 -12 -5 -25 -18 -28 -28 -3 -9 -10 -15
-15 -11 -6 3 -8 13 -4 21 4 12 3 13 -8 5 -16 -14 -28 -6 -20 14 4 10 0 12 -11
8 -9 -3 -16 -9 -16 -13 1 -14 28 -41 41 -41 11 0 11 -2 -1 -10 -9 -5 -19 -5
-24 0 -13 13 -36 0 -36 -19 0 -10 -9 -27 -20 -39 l-21 -22 23 1 c13 1 31 -2
41 -6 12 -5 17 -3 17 9 0 19 21 21 38 4 9 -9 12 -9 12 1 0 8 7 11 20 8 28 -7
26 21 -2 33 -16 6 -18 9 -6 9 9 1 18 7 21 14 9 23 50 -29 49 -62 0 -17 4 -31
9 -31 6 0 8 4 5 8 -6 10 30 52 44 52 6 0 19 -9 30 -20 11 -11 26 -20 33 -20 9
0 8 3 -2 9 -14 9 -15 8 40 69 29 33 35 20 10 -23 -23 -39 -28 -71 -10 -59 12
7 8 -20 -8 -62 -4 -12 -3 -15 6 -10 6 4 18 2 26 -4 15 -12 35 -6 35 11 0 5 -7
9 -15 9 -8 0 -14 3 -13 8 1 4 -2 24 -7 45 -9 44 9 76 22 40 7 -18 10 -19 21
-7 11 12 10 14 -3 14 -8 0 -15 8 -15 18 -1 29 -24 57 -50 60 -18 2 -21 0 -12
-6 24 -16 -35 -65 -80 -67 -21 -1 -35 1 -33 6 3 4 1 10 -4 13 -5 4 -12 2 -16
-4 -8 -13 -22 -5 -34 19 -7 13 -3 25 19 51 45 53 44 95 -4 130 -31 23 -49 27
-76 19z m41 -182 c-16 -16 -26 0 -10 19 9 11 15 12 17 4 2 -6 -1 -17 -7 -23z"/>
<path d="M2320 2901 c0 -7 -5 -9 -12 -5 -7 5 -8 3 -3 -5 5 -9 10 -10 18 -2 6
6 8 14 4 18 -4 3 -7 1 -7 -6z"/>
<path d="M3753 2885 c-3 -9 -3 -19 1 -22 3 -4 6 1 6 11 0 9 4 15 9 11 5 -3 12
-1 16 5 3 5 -1 10 -10 10 -8 0 -18 -7 -22 -15z"/>
<path d="M3820 2871 c0 -5 -9 -11 -20 -13 -12 -2 -20 -7 -20 -13 1 -5 8 -9 16
-7 17 3 19 -15 2 -20 -7 -3 -6 -7 5 -11 12 -5 17 -1 17 13 0 11 5 20 10 20 6
0 10 9 10 20 0 11 -4 20 -10 20 -5 0 -10 -4 -10 -9z"/>
<path d="M2246 2852 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
<path d="M4230 2851 c0 -5 7 -11 15 -15 9 -3 15 0 15 9 0 8 -7 15 -15 15 -8 0
-15 -4 -15 -9z"/>
<path d="M3758 2803 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2028 2769 c37 -39 38 -47 15 -65 -16 -13 -16 -15 -2 -26 8 -7 18
-21 22 -30 4 -12 12 -15 23 -11 15 5 14 9 -10 29 -24 21 -25 26 -14 46 7 13
15 25 18 28 9 9 11 40 3 41 -30 3 -51 8 -68 15 -14 6 -11 -2 13 -27z"/>
<path d="M2219 2784 c-12 -14 -11 -16 5 -13 11 2 21 10 24 17 5 17 -13 15 -29
-4z"/>
<path d="M2118 2783 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3786 2783 c-10 -11 5 -23 28 -23 19 0 22 -4 18 -30 -2 -17 -12 -32
-22 -35 -13 -4 -18 -15 -18 -34 1 -15 -2 -33 -6 -39 -3 -6 -2 -13 4 -17 6 -4
8 -11 5 -16 -4 -5 -2 -9 2 -9 16 0 31 36 22 51 -5 8 -9 17 -9 20 0 7 40 22 53
20 4 -1 7 3 7 8 0 13 27 21 45 15 13 -5 13 -9 3 -22 -10 -12 -10 -16 0 -19 6
-3 10 -9 7 -14 -4 -5 1 -6 10 -3 12 5 13 10 5 18 -7 7 -4 15 12 28 l23 18 -27
0 c-16 0 -28 6 -28 13 0 15 -35 47 -51 47 -6 0 -8 7 -5 16 5 14 4 15 -8 5 -9
-8 -21 -8 -39 -2 -14 5 -28 7 -31 4z"/>
<path d="M2346 2761 c-4 -5 -2 -20 3 -33 9 -21 10 -21 10 10 1 32 -3 39 -13
23z"/>
<path d="M3990 2741 c0 -5 -3 -16 -6 -25 -4 -10 -1 -16 8 -16 20 0 37 20 31
36 -6 16 -33 19 -33 5z"/>
<path d="M4088 2744 c-16 -16 -7 -34 17 -34 14 0 25 4 25 10 0 5 -6 7 -14 4
-8 -3 -16 2 -18 11 -1 9 -6 13 -10 9z"/>
<path d="M4300 2731 c0 -20 -3 -22 -31 -16 -18 4 -49 8 -69 9 -37 2 -38 1 -44
-36 -4 -21 -12 -38 -19 -38 -7 0 -13 -10 -14 -21 -2 -29 -12 -37 -23 -19 -6 8
-10 11 -10 6 0 -5 -11 3 -25 18 -28 30 -51 35 -40 9 4 -10 10 -28 13 -41 3
-17 9 -21 21 -16 9 3 27 7 41 8 31 3 82 35 74 47 -3 5 6 9 21 9 22 0 25 -3 19
-25 -6 -23 -4 -25 20 -21 30 5 33 -1 9 -21 -17 -13 -17 -14 2 -11 37 6 21 118
-17 118 -9 0 -20 5 -23 10 -3 6 1 10 10 10 25 0 56 -20 49 -31 -6 -10 13 -39
26 -39 4 0 5 11 2 25 -3 20 -1 24 11 19 23 -8 33 34 13 54 -15 15 -16 14 -16
-7z m-65 -70 c-3 -6 -11 -8 -17 -5 -6 4 -5 9 3 15 16 10 23 4 14 -10z"/>
<path d="M2370 2731 c0 -5 8 -12 17 -15 15 -6 15 -8 3 -16 -9 -6 -10 -10 -3
-10 20 0 25 19 9 35 -17 17 -26 19 -26 6z"/>
<path d="M4056 2672 c-3 -5 4 -13 15 -20 15 -10 19 -10 19 2 0 15 -27 29 -34
18z"/>
<path d="M3850 2642 c0 -15 44 -47 64 -47 11 0 21 6 24 13 2 6 -3 12 -12 12
-9 0 -16 5 -16 11 0 6 -7 9 -15 5 -8 -3 -15 -1 -15 4 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -8z"/>
<path d="M2354 2629 c-7 -13 -5 -15 24 -23 27 -9 29 5 2 21 -13 9 -21 9 -26 2z"/>
<path d="M3980 2630 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M2193 2591 c2 -24 5 -27 19 -20 16 9 16 11 1 29 -22 26 -23 25 -20
-9z"/>
<path d="M2071 2601 c-8 -5 -11 -16 -8 -25 5 -13 12 -14 33 -6 17 7 31 7 38 1
7 -6 17 -6 26 -1 12 8 11 10 -6 10 -11 0 -29 7 -40 15 -23 17 -25 17 -43 6z"/>
<path d="M3842 2589 c1 -13 8 -23 13 -21 15 3 12 35 -3 40 -9 3 -13 -3 -10
-19z"/>
<path d="M4010 2590 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3932 2549 c3 -37 21 -46 26 -13 2 11 -4 27 -13 34 -15 12 -16 10
-13 -21z"/>
<path d="M2023 2563 c-7 -2 -13 -11 -13 -19 0 -8 -9 -14 -20 -14 -24 0 -26
-13 -5 -30 13 -10 19 -10 35 5 11 10 20 14 20 10 0 -5 6 0 14 10 23 32 5 53
-31 38z"/>
<path d="M2330 2561 c0 -13 -58 -41 -62 -31 -2 5 -15 12 -30 15 -22 6 -28 4
-28 -9 0 -9 -11 -21 -24 -25 -27 -11 -43 -41 -21 -41 8 0 19 9 25 20 6 11 15
18 21 15 5 -4 7 -11 4 -16 -4 -5 5 -9 19 -9 14 0 30 8 35 18 6 9 11 12 11 5 0
-6 9 -15 21 -18 11 -4 24 -18 29 -31 9 -23 35 -42 45 -32 11 10 -9 47 -28 53
-14 4 -17 8 -9 12 6 2 10 9 7 14 -3 5 -2 17 3 26 5 10 7 24 5 31 -5 14 -23 16
-23 3z m0 -41 c-24 -15 -36 -11 -14 4 10 8 21 12 24 10 2 -3 -2 -9 -10 -14z
m-70 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M2390 2546 c0 -14 5 -26 11 -26 8 0 8 -4 2 -12 -16 -20 -22 -55 -9
-55 33 1 46 -5 46 -18 0 -8 9 -15 20 -15 11 0 20 4 20 8 0 17 -39 54 -49 47
-7 -3 -11 3 -11 16 0 16 5 20 19 16 17 -4 17 -3 3 26 -9 17 -17 26 -20 19 -2
-7 -8 -12 -13 -12 -6 0 -8 4 -4 9 3 5 1 12 -5 16 -6 4 -10 -5 -10 -19z"/>
<path d="M3710 2560 c0 -15 21 -23 32 -12 6 6 2 11 -11 15 -12 3 -21 1 -21 -3z"/>
<path d="M4130 2543 c0 -7 -12 -13 -28 -13 -16 0 -37 -7 -48 -16 -19 -15 -19
-16 1 -8 11 4 29 7 40 8 11 1 26 5 32 9 9 5 11 3 7 -7 -3 -9 3 -17 18 -21 13
-4 32 -15 43 -24 11 -8 17 -12 13 -7 -4 5 -3 15 3 23 9 10 6 13 -12 14 -13 1
-30 1 -38 0 -7 -2 -10 2 -6 8 3 6 13 11 21 11 8 0 14 5 14 11 0 6 -9 8 -21 4
-14 -5 -25 -2 -30 6 -6 10 -9 11 -9 2z"/>
<path d="M2100 2528 c0 -7 -9 -24 -20 -38 -17 -21 -21 -23 -29 -10 -8 11 -16
12 -38 4 -30 -10 -39 -44 -13 -52 13 -5 13 -3 1 12 -13 15 -13 19 0 26 9 6 19
4 29 -6 13 -13 13 -16 0 -24 -17 -11 -16 -11 48 -14 29 -1 32 1 27 22 -3 12
-1 22 5 22 6 0 8 -4 5 -10 -3 -5 -1 -10 5 -10 6 0 8 -4 5 -10 -8 -13 1 -13 27
1 18 10 20 9 14 -6 -5 -13 -2 -16 9 -11 9 3 14 2 11 -2 -3 -4 4 -13 14 -20 14
-9 19 -8 24 3 3 8 13 15 22 15 11 0 14 5 10 16 -3 9 -6 18 -6 20 0 2 -8 4 -17
4 -15 0 -16 -2 -3 -10 9 -6 10 -10 3 -10 -6 0 -15 5 -19 11 -4 7 -22 9 -45 6
-43 -6 -50 6 -27 43 10 16 10 22 -1 32 -11 10 -12 10 -7 1 4 -7 2 -13 -3 -13
-6 0 -11 5 -11 10 0 15 -20 12 -20 -2z"/>
<path d="M3905 2530 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2270 2480 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3930 2480 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M3972 2471 c-10 -6 -10 -10 3 -21 19 -15 25 -11 16 12 -4 11 -11 14
-19 9z"/>
<path d="M4032 2458 c-7 -7 -12 -18 -12 -25 0 -7 -5 -13 -10 -13 -11 0 -9 -58
3 -77 4 -6 3 -14 -2 -18 -21 -12 1 -36 31 -33 17 2 32 -3 38 -11 5 -10 7 -2 5
23 -3 31 -1 36 13 32 11 -4 22 2 30 14 10 16 10 20 -2 20 -8 0 -16 -5 -18 -12
-3 -8 -12 -4 -25 9 -12 12 -24 18 -28 15 -4 -4 -1 6 6 21 8 17 18 26 27 22 9
-3 12 1 10 13 -2 9 -10 16 -17 14 -7 -1 -19 2 -25 7 -9 7 -16 7 -24 -1z"/>
<path d="M2270 2443 c0 -21 19 -24 29 -5 8 14 6 18 -10 18 -10 0 -19 -6 -19
-13z"/>
<path d="M3576 2441 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"/>
<path d="M4138 2442 c-20 -4 -22 -10 -8 -32 8 -13 11 -13 24 0 18 18 8 37 -16
32z"/>
<path d="M3777 2417 c-14 -21 -13 -24 3 -35 23 -14 32 -5 19 22 -9 18 -8 19 3
5 16 -19 58 -20 58 -1 0 7 -5 10 -10 7 -6 -3 -17 1 -26 9 -21 21 -29 20 -47
-7z"/>
<path d="M2300 2415 c-7 -8 -16 -13 -20 -10 -4 3 -10 -1 -13 -9 -4 -10 0 -12
15 -8 11 2 22 11 25 18 2 7 12 10 20 7 11 -4 14 -2 9 5 -10 16 -21 15 -36 -3z"/>
<path d="M2390 2413 c0 -11 -6 -25 -12 -32 -10 -11 -10 -14 0 -18 6 -3 12 -11
12 -19 0 -8 -6 -14 -12 -14 -7 0 -5 -5 5 -11 9 -6 15 -14 12 -19 -3 -5 0 -12
7 -16 7 -4 10 -4 6 1 -4 4 0 14 10 22 12 9 13 13 3 11 -8 -2 -16 5 -19 14 -3
14 3 16 37 13 23 -2 41 0 41 5 0 18 -12 40 -20 35 -9 -6 -29 16 -27 30 1 6 -8
11 -20 13 -17 3 -23 -2 -23 -15z"/>
<path d="M1940 2410 c-8 -5 -12 -11 -10 -14 3 -2 14 2 24 10 22 15 10 19 -14
4z"/>
<path d="M2130 2385 c0 -31 3 -35 26 -35 15 0 23 -4 18 -11 -3 -6 -17 -8 -30
-5 -19 5 -24 2 -24 -13 0 -13 -5 -18 -13 -15 -8 3 -14 -1 -14 -9 0 -11 8 -12
40 -4 30 8 41 7 47 -3 5 -9 8 -3 9 16 1 56 2 64 12 64 6 0 7 5 4 10 -3 6 -15
10 -26 10 -11 0 -22 7 -25 15 -11 27 -24 16 -24 -20z"/>
<path d="M3621 2390 c0 -33 1 -34 10 -11 6 16 6 28 -1 35 -7 7 -10 -1 -9 -24z"/>
<path d="M1980 2390 c0 -14 5 -18 17 -13 11 4 13 8 5 11 -7 2 -10 8 -6 13 3 5
0 9 -5 9 -6 0 -11 -9 -11 -20z"/>
<path d="M4103 2395 c0 -8 4 -15 9 -15 4 0 8 7 8 15 0 8 -4 15 -8 15 -5 0 -9
-7 -9 -15z"/>
<path d="M2050 2390 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M1941 2361 c-7 -5 -9 -11 -5 -14 5 -3 15 1 22 9 14 16 5 18 -17 5z"/>
<path d="M2014 2354 c3 -9 6 -18 6 -20 0 -2 4 -4 9 -4 5 0 7 9 4 20 -3 11 -10
20 -15 20 -6 0 -7 -7 -4 -16z"/>
<path d="M2249 2361 c7 -5 11 -13 9 -17 -10 -17 9 -47 30 -51 35 -5 29 38 -7
60 -29 17 -58 24 -32 8z"/>
<path d="M2322 2359 c2 -7 10 -15 17 -17 8 -3 12 1 9 9 -2 7 -10 15 -17 17 -8
3 -12 -1 -9 -9z"/>
<path d="M3664 2362 c-8 -5 -8 -15 -2 -31 8 -23 9 -24 23 -5 26 34 12 58 -21
36z"/>
<path d="M3160 2351 c0 -6 -17 -7 -37 -5 -28 4 -34 3 -23 -5 8 -6 15 -18 15
-28 0 -14 8 -18 37 -18 21 0 40 6 43 13 2 6 0 12 -5 12 -6 0 -10 5 -10 10 0
18 19 10 25 -10 5 -16 12 -19 27 -14 15 4 18 3 13 -7 -6 -9 -3 -10 11 -5 10 4
15 4 11 0 -4 -4 -2 -13 4 -21 9 -10 7 -13 -10 -13 -12 0 -24 7 -27 15 -9 23
-24 7 -24 -26 0 -17 -4 -28 -9 -25 -5 4 -7 -4 -4 -15 4 -14 0 -25 -11 -31 -14
-8 -15 -13 -5 -24 13 -16 6 -29 -18 -29 -23 0 -41 -31 -26 -46 10 -10 17 -9
32 5 11 10 19 23 18 29 -1 6 9 16 23 24 24 12 24 13 3 13 -12 0 -24 4 -27 9
-7 11 61 20 72 9 4 -4 7 12 7 36 -2 36 1 43 13 38 10 -3 13 -1 9 5 -4 6 4 20
18 31 14 11 25 26 25 33 0 11 3 11 10 -1 5 -8 7 -24 4 -35 -3 -11 4 -5 15 13
12 18 21 33 21 35 0 1 -18 2 -41 3 -25 1 -36 -2 -27 -7 10 -7 9 -9 -4 -9 -9 0
-20 5 -23 11 -4 6 -13 8 -21 5 -12 -5 -12 -2 -3 15 9 18 8 20 -8 17 -10 -2
-18 -8 -18 -15 0 -23 -16 -27 -22 -5 -3 15 -13 22 -29 22 -13 0 -24 -4 -24 -9z
m95 -131 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4
21 -10z"/>
<path d="M3776 2345 c-15 -8 -26 -10 -26 -4 0 6 -7 9 -17 7 -25 -5 -25 -41 0
-69 19 -22 25 -24 47 -15 26 11 34 44 15 59 -6 5 0 7 13 4 14 -3 22 0 22 8 0
20 -25 25 -54 10z"/>
<path d="M1987 2329 c-10 -35 -10 -36 24 -36 17 0 35 -6 40 -14 7 -11 9 -9 9
8 0 16 -5 20 -19 16 -14 -3 -24 3 -34 22 l-14 28 -6 -24z"/>
<path d="M2961 2341 c-10 -7 -10 -10 -1 -13 10 -3 10 -9 1 -26 -15 -28 3 -29
22 -1 8 11 18 16 21 11 9 -14 46 -2 46 14 0 21 17 17 23 -6 3 -11 10 -20 16
-20 7 0 5 10 -5 25 -14 21 -24 25 -63 25 -25 0 -52 -4 -60 -9z"/>
<path d="M1950 2308 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10
-22z"/>
<path d="M2730 2321 c0 -5 -15 -15 -34 -21 -22 -7 -36 -19 -40 -34 -4 -18 -15
-25 -44 -30 -22 -3 -55 -12 -74 -20 -35 -15 -35 -15 -81 -188 -26 -95 -49
-188 -53 -206 -4 -17 -12 -32 -18 -32 -7 0 -12 -16 -11 -40 0 -37 3 -41 30
-48 17 -5 34 -18 40 -30 5 -12 13 -22 17 -22 5 0 9 -19 9 -42 l1 -43 8 40 c4
21 13 41 19 43 6 2 11 12 11 23 0 26 20 24 87 -10 50 -26 58 -28 68 -14 10 13
46 16 236 17 195 2 234 5 301 24 42 11 87 28 100 36 13 8 57 28 98 44 127 51
300 154 301 181 0 10 2 11 6 4 3 -9 10 -10 24 -3 23 13 25 30 3 30 -8 0 -12
-4 -9 -10 8 -13 -1 -13 -27 1 -24 13 -35 5 -19 -15 9 -11 8 -15 -5 -20 -22 -8
-40 4 -23 15 11 7 11 9 1 9 -7 0 -20 -15 -29 -34 -11 -23 -21 -32 -31 -28 -9
3 -13 1 -9 -5 7 -10 -13 -23 -35 -23 -7 0 -6 5 1 14 9 10 8 16 -3 26 -17 13
-36 6 -36 -15 0 -18 -21 -24 -42 -13 -24 12 -11 41 14 35 18 -5 19 -4 4 12 -9
10 -16 23 -16 29 0 7 -7 12 -16 12 -9 0 -28 9 -43 19 -25 18 -27 18 -49 1 -25
-21 -29 -32 -7 -24 20 8 19 -6 -2 -23 -17 -13 -16 -14 5 -8 18 5 25 2 29 -14
7 -27 19 -27 27 2 7 21 7 21 16 2 11 -27 12 -37 1 -31 -5 3 -15 -1 -22 -10
-12 -14 -26 -14 -80 2 -12 3 -16 -2 -15 -26 1 -29 16 -44 16 -16 0 10 9 13 31
11 38 -5 45 -11 24 -18 -8 -4 -15 -2 -15 3 0 6 -4 10 -9 10 -5 0 -7 -10 -4
-22 3 -13 2 -20 -2 -16 -4 4 -16 3 -26 -2 -12 -7 -19 -6 -22 3 -4 9 -7 9 -18
0 -16 -16 -39 -17 -39 -2 0 6 7 9 14 6 10 -4 13 -1 10 7 -3 7 2 16 10 19 9 4
16 11 16 16 0 18 -24 2 -45 -29 -22 -33 -38 -40 -28 -12 6 15 5 15 -10 3 -11
-8 -17 -9 -17 -3 0 13 35 34 43 26 7 -8 47 57 42 67 -3 4 2 9 9 12 8 3 17 1
19 -6 2 -7 8 -9 12 -5 4 4 2 14 -5 23 -10 12 -10 18 0 30 7 8 9 15 4 15 -5 0
-1 5 9 11 9 5 17 15 17 20 0 6 -5 7 -10 4 -6 -4 -17 -2 -24 4 -18 15 -49 2
-42 -18 21 -66 21 -67 1 -51 -10 8 -16 10 -12 3 5 -8 -4 -14 -28 -19 -19 -3
-35 -10 -35 -15 0 -5 6 -6 13 -3 8 3 14 -2 14 -10 0 -8 -4 -13 -8 -11 -3 3
-13 -3 -20 -12 -12 -14 -12 -16 0 -9 9 6 12 5 9 -5 -6 -15 -39 -13 -49 3 -10
16 -28 1 -24 -20 2 -9 4 -20 4 -24 1 -12 31 -9 31 2 0 6 5 10 10 10 6 0 10 -9
10 -20 0 -22 -20 -26 -50 -10 -13 7 -21 7 -25 0 -3 -5 -13 -10 -20 -10 -11 0
-12 6 -5 26 10 25 9 25 -20 19 -26 -6 -29 -4 -23 11 5 13 3 16 -10 11 -18 -7
-24 8 -7 18 14 8 3 25 -16 25 -8 0 -12 -4 -9 -10 3 -5 1 -10 -5 -10 -6 0 1
-16 15 -35 30 -39 26 -58 -12 -49 -14 4 -34 7 -44 8 -22 1 -49 35 -42 53 4 9
-6 13 -32 13 -30 0 -36 -3 -31 -16 3 -9 6 -18 6 -20 0 -2 7 -4 15 -4 8 0 15
-5 15 -11 0 -7 -12 -8 -32 -4 -61 12 -67 15 -61 32 7 17 3 17 -49 -9 -28 -13
-37 -28 -18 -28 5 0 7 -6 4 -13 -2 -7 15 -25 41 -41 75 -48 52 -44 -45 7 l-55
29 -4 -24 c-1 -12 -8 -26 -13 -29 -6 -4 -14 -24 -18 -45 l-8 -39 -1 43 c0 23
-5 42 -11 42 -5 0 -10 6 -10 14 0 8 -10 22 -22 31 -13 8 -22 16 -20 18 8 5
-13 44 -32 59 -26 22 -7 23 41 3 45 -19 43 -19 43 9 0 13 7 26 15 30 8 3 15
16 15 28 0 13 5 30 10 38 8 12 10 10 10 -9 0 -16 4 -21 15 -17 9 3 15 -1 15
-11 0 -12 3 -13 13 -4 8 6 17 8 20 4 15 -15 5 5 -18 37 -20 27 -25 30 -25 14
0 -16 -2 -16 -10 -4 -7 11 -4 17 9 24 11 4 22 21 25 37 4 24 11 29 33 29 l27
0 -22 16 c-24 17 -42 12 -42 -12 0 -8 -4 -12 -10 -9 -5 3 -10 -1 -10 -9 0 -9
-4 -16 -9 -16 -11 0 -3 66 11 93 9 17 11 17 35 -7 26 -27 48 -33 57 -17 4 5
-3 7 -15 4 -19 -5 -20 -4 -8 15 11 17 16 19 28 9 12 -10 12 -9 1 10 -8 11 -27
25 -44 30 -36 10 -41 37 -8 41 17 3 22 10 22 35 l0 32 14 -27 c8 -15 21 -36
28 -47 14 -20 14 -20 26 0 7 11 11 23 8 27 -4 7 18 12 61 15 18 1 -5 27 -23
27 -17 0 -18 27 -1 33 7 4 6 6 -4 6 -9 1 -23 -7 -30 -16 -12 -14 -12 -16 0 -8
9 5 7 -2 -5 -18 -22 -31 -49 -31 -48 1 0 12 -5 22 -13 22 -7 0 -13 5 -13 10 0
6 -7 10 -15 10 -8 0 -15 -4 -15 -9z m690 -357 c0 -20 -20 -27 -21 -7 0 16 -1
16 -9 -2 -8 -18 -9 -17 -9 7 -1 21 3 25 19 21 11 -3 20 -11 20 -19z m-160 -61
c-1 -24 -2 -26 -11 -10 -5 9 -7 22 -4 27 10 17 15 11 15 -17z m254 -42 c7 -10
-17 -21 -42 -21 -15 1 -14 3 2 15 23 17 33 19 40 6z"/>
<path d="M2890 2310 c0 -10 7 -21 16 -24 8 -3 12 -2 9 3 -3 6 2 14 11 19 14 8
12 10 -10 14 -20 4 -26 1 -26 -12z"/>
<path d="M3637 2300 c4 -14 -1 -26 -13 -35 -15 -11 -17 -17 -8 -32 7 -10 19
-17 28 -17 9 1 16 -4 16 -12 0 -17 12 -18 28 -2 9 9 7 15 -6 25 -15 13 -15 13
8 7 14 -4 28 -12 32 -18 6 -8 8 -7 8 1 0 7 -9 17 -20 23 -11 6 -19 16 -17 22
1 7 -8 17 -20 23 -13 5 -23 15 -23 22 0 7 -4 13 -9 13 -5 0 -7 -9 -4 -20z"/>
<path d="M2346 2291 c-3 -5 3 -11 13 -14 14 -3 18 -1 14 9 -6 16 -19 18 -27 5z"/>
<path d="M3030 2280 c-8 -5 -11 -12 -8 -16 4 -3 14 1 23 10 18 17 9 21 -15 6z"/>
<path d="M3987 2284 c-13 -14 -7 -23 11 -17 15 6 14 3 -3 -17 l-20 -23 23 12
c13 7 22 20 20 28 -3 18 -21 27 -31 17z"/>
<path d="M3070 2260 c0 -14 5 -18 17 -13 10 4 14 2 10 -4 -4 -5 0 -19 8 -29
14 -19 14 -19 14 1 0 19 -30 65 -43 65 -3 0 -6 -9 -6 -20z"/>
<path d="M2010 2261 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M2118 2263 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2960 2259 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3137 2259 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M2055 2234 c-11 -12 -12 -20 -4 -36 10 -19 12 -18 35 13 13 18 24 33
24 34 0 0 -9 1 -20 2 -11 1 -27 -5 -35 -13z"/>
<path d="M2400 2242 c-68 -23 -67 -22 -46 -38 11 -8 16 -14 11 -14 -4 0 0 -9
9 -19 10 -11 16 -23 13 -28 -3 -4 7 -17 22 -27 14 -11 20 -13 14 -6 -7 8 -13
24 -13 36 0 12 -6 25 -12 27 -8 3 -6 6 4 6 26 1 44 40 26 56 -7 7 -20 10 -28
7z"/>
<path d="M3566 2243 c-6 -6 23 -32 36 -33 4 0 5 6 2 14 -6 16 -29 28 -38 19z"/>
<path d="M3327 2217 c3 -12 7 -29 11 -37 3 -8 6 -23 7 -32 1 -9 5 -35 9 -57 5
-34 4 -39 -9 -35 -11 4 -13 2 -10 -9 4 -8 18 -14 32 -14 22 0 24 3 20 31 -2
17 2 40 9 52 13 19 12 22 -13 34 -16 7 -23 15 -15 17 28 11 10 52 -29 66 -13
4 -16 1 -12 -16z"/>
<path d="M3390 2230 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M3781 2227 c-8 -10 -7 -14 2 -14 8 0 14 6 14 14 0 7 -1 13 -2 13 -2
0 -8 -6 -14 -13z"/>
<path d="M2927 2224 c-3 -3 -3 -13 0 -22 4 -15 3 -14 -10 1 -13 15 -15 16 -21
2 -6 -17 29 -59 43 -50 9 5 9 60 0 69 -3 3 -8 3 -12 0z"/>
<path d="M3028 2218 c7 -7 12 -21 12 -32 0 -16 2 -18 9 -7 10 15 -7 51 -23 51
-6 0 -5 -5 2 -12z"/>
<path d="M2246 2204 c-9 -24 -2 -26 12 -4 7 11 8 20 3 20 -5 0 -12 -7 -15 -16z"/>
<path d="M2965 2210 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3081 2213 c-1 -6 -4 -19 -8 -29 -4 -12 -2 -15 8 -12 8 3 26 -1 42
-9 25 -13 27 -12 27 9 0 16 -3 19 -11 11 -15 -15 -39 -5 -49 21 -5 12 -9 16
-9 9z"/>
<path d="M3967 2210 c9 -10 9 -17 2 -26 -6 -7 -9 -21 -6 -31 4 -16 6 -15 12 4
4 12 18 24 32 28 30 7 21 21 -22 31 -24 5 -28 4 -18 -6z"/>
<path d="M3525 2190 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M3628 2183 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3719 2173 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M2200 2170 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M2800 2171 c0 -6 8 -11 18 -11 19 0 58 -39 54 -53 -1 -5 3 -5 9 -1 6
3 15 1 19 -6 7 -11 11 -10 24 2 14 14 13 17 -7 23 -12 4 -26 8 -32 9 -5 1 -17
8 -25 17 -19 21 -60 34 -60 20z"/>
<path d="M3743 2167 c-3 -5 1 -20 8 -34 10 -19 11 -28 1 -44 -11 -16 -9 -19 9
-19 11 0 27 6 35 14 22 22 17 32 -6 11 -16 -14 -20 -15 -20 -4 0 8 9 22 20 32
11 9 20 23 20 31 0 16 -58 28 -67 13z"/>
<path d="M3420 2160 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M3900 2159 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3645 2151 c-16 -4 -49 -6 -72 -5 -24 1 -40 -2 -37 -7 3 -5 0 -9 -7
-9 -7 0 -10 -3 -6 -6 3 -4 20 -2 36 4 19 7 31 7 31 1 0 -5 -6 -9 -14 -9 -12 0
-12 -4 0 -30 16 -31 28 -41 18 -15 -4 8 -4 22 0 30 5 13 9 13 28 3 13 -7 26
-17 29 -22 4 -5 11 -6 17 -2 6 3 13 2 17 -4 9 -15 25 -2 19 15 -3 7 -12 11
-19 9 -9 -4 -12 3 -11 25 1 17 2 31 2 30 -1 -1 -14 -4 -31 -8z"/>
<path d="M3090 2146 c0 -2 7 -9 15 -16 12 -10 15 -10 15 4 0 9 -7 16 -15 16
-8 0 -15 -2 -15 -4z"/>
<path d="M3269 2143 c-9 -2 -19 -16 -22 -31 -3 -15 -9 -39 -13 -54 -8 -34 21
-52 41 -25 7 10 17 17 23 17 6 0 13 4 16 9 8 12 -37 1 -45 -12 -4 -5 -9 3 -11
17 -3 15 0 31 8 37 11 9 14 7 14 -7 0 -16 2 -15 16 5 14 19 14 25 3 35 -8 7
-21 11 -30 9z"/>
<path d="M3065 2130 c-4 -7 -21 -9 -45 -5 -24 4 -41 2 -45 -5 -11 -18 2 -24
55 -25 35 -1 51 3 55 14 7 18 -10 37 -20 21z"/>
<path d="M3445 2117 c-41 -13 -41 -12 -12 -50 14 -19 24 -37 22 -39 -3 -3 4
-13 16 -24 l21 -19 -15 37 c-15 36 -15 38 4 48 21 12 26 45 7 52 -7 3 -26 1
-43 -5z"/>
<path d="M3916 2123 c-9 -9 -7 -33 3 -33 12 0 22 28 12 34 -5 3 -11 3 -15 -1z"/>
<path d="M2785 2099 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2950 2091 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M2350 2065 c0 -8 4 -15 9 -15 5 0 11 -12 14 -26 3 -18 1 -24 -9 -20
-29 11 -22 -13 7 -28 38 -19 47 -20 31 -4 -14 14 -16 54 -3 62 5 3 7 15 4 27
-4 16 -8 17 -14 7 -7 -10 -9 -10 -9 0 0 6 -7 12 -15 12 -8 0 -15 -7 -15 -15z"/>
<path d="M3822 2056 c2 -15 8 -26 13 -26 15 0 12 43 -3 48 -10 3 -13 -4 -10
-22z"/>
<path d="M3510 2053 c0 -7 4 -13 8 -13 5 0 16 6 25 13 16 11 15 12 -8 12 -14
0 -25 -6 -25 -12z"/>
<path d="M3630 2055 c-7 -9 -19 -14 -27 -11 -7 3 -13 1 -13 -4 0 -13 37 -13
45 1 5 6 12 4 20 -8 7 -10 17 -15 21 -10 4 4 2 7 -5 7 -7 0 -10 4 -7 9 3 5 0
14 -8 20 -10 9 -16 7 -26 -4z"/>
<path d="M3128 2042 c-19 -2 -25 -8 -21 -18 3 -9 -1 -14 -12 -14 -9 0 -14 4
-11 9 3 5 -1 12 -9 15 -24 9 -75 -5 -75 -20 0 -16 -43 -29 -60 -19 -6 4 -27
-5 -46 -20 -34 -26 -35 -28 -18 -47 18 -19 18 -18 36 16 20 39 27 43 47 24 10
-11 16 -9 30 7 9 11 27 26 39 33 19 11 22 10 22 -7 0 -10 7 -22 16 -25 8 -3
12 -10 9 -16 -3 -5 1 -20 9 -32 18 -25 21 -42 7 -33 -5 3 -12 1 -16 -5 -3 -5
6 -12 21 -16 26 -7 49 10 38 28 -7 11 14 10 37 -2 19 -11 29 3 10 14 -4 3 -11
20 -15 38 -6 29 -8 31 -18 14 -8 -14 -7 -21 3 -27 12 -7 11 -9 -1 -8 -8 0 -24
4 -35 9 -19 8 -19 9 -1 9 15 1 16 4 6 16 -9 11 -9 15 -1 15 7 0 10 4 6 9 -3 5
-1 12 5 16 5 3 10 1 10 -6 0 -8 3 -9 13 0 8 6 17 8 20 4 4 -3 7 1 7 10 0 10 1
20 3 24 3 8 -19 10 -55 5z"/>
<path d="M3560 2035 c0 -8 -9 -15 -19 -15 -11 0 -22 -4 -25 -8 -3 -5 9 -6 25
-4 21 3 34 -1 44 -14 17 -24 9 -37 -14 -22 -11 7 -26 8 -37 3 -18 -8 -17 -9 4
-13 12 -2 28 -12 35 -23 15 -25 27 -24 27 2 0 13 4 18 12 13 7 -4 8 -3 4 4 -4
7 -3 12 1 12 5 0 9 9 9 20 0 13 -9 21 -29 26 -19 4 -27 11 -23 20 3 8 1 14 -4
14 -6 0 -10 -7 -10 -15z"/>
<path d="M3726 2025 c-3 -9 -12 -12 -23 -8 -10 3 -15 3 -12 -1 13 -14 49 -15
49 0 0 8 5 14 11 14 6 0 9 -9 6 -20 -3 -11 -1 -22 4 -25 14 -9 49 14 49 32 0
13 -2 14 -17 2 -15 -12 -16 -12 -10 4 5 14 1 17 -22 17 -16 0 -31 -7 -35 -15z"/>
<path d="M2720 2016 c0 -7 6 -19 14 -25 11 -9 15 -7 19 8 3 11 2 22 -1 25 -11
11 -32 6 -32 -8z"/>
<path d="M2799 2018 c-21 -18 -49 -64 -49 -82 0 -8 -6 -17 -12 -19 -8 -4 -7
-6 4 -6 10 -1 24 15 35 39 10 22 24 40 31 40 6 0 12 9 12 20 0 23 -1 24 -21 8z"/>
<path d="M2900 2020 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3203 1990 c-3 -11 -9 -20 -14 -20 -5 0 -9 -8 -9 -17 0 -14 2 -15 9
-5 4 8 12 14 17 15 9 2 16 47 7 47 -3 0 -7 -9 -10 -20z"/>
<path d="M2207 1963 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M2817 1930 c-3 -11 -1 -20 4 -20 5 0 9 9 9 20 0 11 -2 20 -4 20 -2 0
-6 -9 -9 -20z"/>
<path d="M2938 1929 c-31 -23 -27 -49 8 -49 13 0 23 -5 22 -12 -2 -6 5 -14 16
-16 13 -3 16 0 11 9 -9 14 -16 79 -9 79 3 0 14 -5 24 -10 16 -8 18 -8 14 5 -8
21 -54 18 -86 -6z"/>
<path d="M2720 1890 c0 -6 -4 -8 -10 -5 -5 3 -10 -4 -10 -16 0 -17 4 -20 23
-15 12 3 25 6 30 6 15 0 6 20 -13 30 -14 8 -20 8 -20 0z"/>
<path d="M2676 1865 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
